import { Modal } from "antd";
import { ModalProps } from "antd/lib/modal";
import Icon from "Components/icons/icon";
import React, { FunctionComponent } from "react";
import "./index.less";

export const BaseModal: FunctionComponent<ModalProps> = ({
  children,
  centered = true,
  closeIcon = <Icon iconName="Cross" />,
  footer = null,
  maskClosable = false,
  width,
  ...props
}) => {
  return (
    <Modal
      centered={centered}
      closeIcon={closeIcon}
      footer={footer}
      maskClosable={maskClosable}
      width={width || 885}
      {...props}
    >
      {children}
    </Modal>
  );
};
