import { UserDetailsDto } from "Api/Features/Users/Dtos/UserDetailsDto";
import { inject } from "aurelia-dependency-injection";
import { action, observable } from "mobx";
import { UserService } from "Services/UserService";
import { AuthenticationStore } from "Stores";

@inject(UserService, AuthenticationStore)
class UserStore {
  constructor(
    private readonly userService: UserService,
    private readonly authenticationStore: AuthenticationStore
  ) {}

  @observable
  public userInfo: UserDetailsDto | null = null;

  @action
  async setUserInfo(): Promise<void> {
    const userId = this.authenticationStore.userId;

    try {
      const userInfo = await this.userService.getUser(userId!);
      this.userInfo = userInfo;
    } catch {
      this.authenticationStore.clearSession();
      this.clearUserInfo();
    }
  }

  @action
  public clearUserInfo(): void {
    this.userInfo = null;
  }
}

export default UserStore;
