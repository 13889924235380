import "antd/dist/antd.css";
import { ApiHttpClient } from "Api/ApiHttpClient";
import App from "App/App";
import { Container } from "aurelia-dependency-injection";
import { HttpClient } from "HttpClient/HttpClient";
import { Environment } from "Misc/Environment";
import React from "react";
import { render } from "react-dom";
import "reflect-metadata";
import reportWebVitals from "./reportWebVitals";
import "./Styles/main.less";
import "./Styles/normalize.less";

// Setup i18n
import "i18n";

declare global {
  interface Window {
    Environment: Environment;
  }
}

// Setup DI
const rootContainer = new Container();
rootContainer.makeGlobal();

// Register the object containing environment variables.
if (!window.Environment) {
  throw new Error("Environment object not found.");
}
rootContainer.registerInstance(Environment, window.Environment);

// Register HttpClient under both HttpClient and ApiHttpClient.
rootContainer.registerSingleton(HttpClient, HttpClient);
const httpClient = rootContainer.get(HttpClient);
rootContainer.registerInstance(ApiHttpClient, httpClient);

const rootElement = document.getElementById("root");
render(<App />, rootElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
