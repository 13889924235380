import { ArticleTypeDto } from 'Api/Features/Articles/Dtos/ArticleTypeDto';
import { BaseModal } from 'Components/base-modal';
import { BaseWindow } from 'Components/base-window';
import { CreateEditArticleForm } from 'Components/create-edit-article-form';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

interface CreateNewsModalProps {
    isVisible?: boolean;
    setIsVisible?: Dispatch<SetStateAction<boolean>>;
}

export const CreateNewsModal: FunctionComponent<CreateNewsModalProps> = observer(
    ({ isVisible, setIsVisible }) => {
        const { confirmationModalStore } = useStores();
        const { t } = useTranslation();

        const exit = async (success: boolean): Promise<boolean> => {
            if (!success) {
                if (
                    !(await confirmationModalStore.confirm({
                        title: t('addition_of', { param1: t('a_news') }),
                        message: t('add_confirm_message', { param1: t('a_news') }),
                        positiveText: t('confirm'),
                        negativeText: t('cancel'),
                    }))
                )
                    return false;
            }

            setIsVisible && setIsVisible(false);

            return true;
        };

        return (
            <BaseModal visible={isVisible} className="CreateNewsModal" onCancel={() => exit(false)}>
                <BaseWindow title={t('addition_of', { param1: t('a_news') })}>
                    <CreateEditArticleForm articleType={ArticleTypeDto.News} onCancel={exit} />
                </BaseWindow>
            </BaseModal>
        );
    }
);
