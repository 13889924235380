import { AuthenticatedLayout } from "Components/authenticated-layout";
import { useStores } from "Hooks";
import { observer } from "mobx-react";
import { ADMINISTRATORS, NEWS, PRESS_RELEASES } from "Models/paths";
import { FunctionComponent, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Administrators } from "./administrators";
import { AdministratorDetails } from "./administrators/id";
import { News } from "./news";
import { NewsDetails } from "./news/id";
import { PressReleases } from "./press-releases";
import { PressReleaseDetails } from "./press-releases/id";

interface AuthenticatedProps {
  returnUrl: string;
}

const AuthenticatedRoutes: FunctionComponent<AuthenticatedProps> = observer(
  ({ returnUrl }) => {
    const location = useLocation();
    const { authorizationStore, userStore } = useStores();
    let redirectionUrl = returnUrl;

    if (!returnUrl) redirectionUrl = ADMINISTRATORS;

    useEffect(() => {
      // Reset forbidden and notFound state on route change.
      authorizationStore.resetForbidden();
      authorizationStore.resetNotFound();
    }, [location, authorizationStore]);

    useEffect(() => {
      userStore.setUserInfo();
    }, [userStore]);

    return (
      <>
        <Routes>
          <Route path="/" element={<AuthenticatedLayout />}>
            <Route
              path="/"
              element={<Navigate to={`/${redirectionUrl}`} replace />}
            />

            <Route path={`/${ADMINISTRATORS}`} element={<Administrators />} />
            <Route
              path={`/${ADMINISTRATORS}/:id`}
              element={<AdministratorDetails />}
            />

            <Route path={`/${NEWS}`} element={<News />} />
            <Route path={`/${NEWS}/:id`} element={<NewsDetails />} />

            <Route path={`/${PRESS_RELEASES}`} element={<PressReleases />} />
            <Route
              path={`/${PRESS_RELEASES}/:id`}
              element={<PressReleaseDetails />}
            />
          </Route>

          <Route path="*" element={<Navigate to={`/${ADMINISTRATORS}`} />} />
        </Routes>
      </>
    );
  }
);

export default AuthenticatedRoutes;
