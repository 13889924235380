import * as React from "react";
import { FunctionComponent } from "react";
import { SvgProps } from "./SvgProps";

const ArrowLeft: FunctionComponent<SvgProps> = ({
  fill = "currentColor",
  ...props
}) => {
  return (
    <span role="img" className="anticon">
      <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill}
          d="M9.54126 15.6049C9.54126 15.9565 9.67184 16.2578 9.95309 16.519L17.7678 24.173C17.9888 24.394 18.2701 24.5145 18.6015 24.5145C19.2645 24.5145 19.7968 23.9922 19.7968 23.3192C19.7968 22.9877 19.6562 22.6964 19.4352 22.4654L12.3939 15.6049L19.4352 8.74442C19.6562 8.51339 19.7968 8.21205 19.7968 7.89062C19.7968 7.21763 19.2645 6.69531 18.6015 6.69531C18.2701 6.69531 17.9888 6.81585 17.7678 7.03683L9.95309 14.6808C9.67184 14.952 9.54126 15.2533 9.54126 15.6049Z"
        />
      </svg>
    </span>
  );
};

export default ArrowLeft;
