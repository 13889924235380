import { ColumnType, TablePaginationConfig } from 'antd/lib/table';
import { GetArticlesRequestDto } from 'Api/Features/Articles/Dtos/GetArticlesRequestDto';
import { GetArticlesSortColumnDto } from 'Api/Features/Articles/Dtos/GetArticlesSortColumnDto';
import { SortDirectionDto } from 'Api/Features/General/Dtos/SortDirectionDto';
import { MainContext } from 'Components/authenticated-layout';
import { BaseTable, initialPaginationState } from 'Components/base-table';
import { useService, useStores } from 'Hooks';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArticleService } from 'Services/ArticleService';
import { displayFormatedDate } from 'Utils/TimeUtils';
import './index.less';
import { CreateNewsModal } from './modals/create-news-modal';

interface NewsProps {
    key: string;
    id: string;
    titleFR: string;
    date: string;
}

export const News = observer(() => {
    const mainContext = useContext(MainContext);
    const articleService = useService(ArticleService);
    const { toastStore } = useStores();
    const [newsList, setNewsList] = useState<NewsProps[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const paginationRef = useRef(initialPaginationState);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const { t } = useTranslation();

    const columns: ColumnType<NewsProps>[] = [
        {
            key: GetArticlesSortColumnDto.TitleFr,
            title: t('title'),
            dataIndex: 'titleFR',
            sorter: true,
        },
        {
            key: GetArticlesSortColumnDto.Date,
            title: t('date'),
            dataIndex: 'date',
            sorter: true,
        },
    ];

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            searchTerm?: string;
            sortColumn: GetArticlesSortColumnDto | null;
            sortDirection: SortDirectionDto | null;
        }) => {
            setIsLoading(true);

            try {
                const request: GetArticlesRequestDto = {
                    pageSize: params.pagination.pageSize || 0,
                    page: (params.pagination.current || 1) - 1,
                    searchTerm: params.searchTerm,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                };

                const response = await articleService.getNews(request);

                const mapItems: NewsProps[] = response.items.map((item) => ({
                    key: item.id || '',
                    id: item.id || '',
                    titleFR: item.title || '',
                    date: displayFormatedDate(item.date) || '',
                }));

                setNewsList(mapItems);
                setPagination({
                    ...params.pagination,
                    total: response.totalItemCount,
                });
            } catch (error) {
                if (!(error as any).treated) {
                    toastStore.genericError();
                }
            } finally {
                setIsLoading(false);
            }
        },
        [articleService, toastStore]
    );

    useEffect(() => {
        const disposer = autorun(() => {
            fetch({
                pagination: {
                    ...paginationRef.current,
                    current: 1,
                },
                sortColumn: null,
                sortDirection: null,
            });
        });

        return (): void => {
            disposer();
        };
    }, [fetch, mainContext?.articles.news.createNewsModalOpen]);

    return (
        <div className="News">
            <BaseTable
                fetch={fetch}
                columns={columns}
                dataSource={newsList}
                loading={isLoading}
                pagination={pagination}
                paginationRef={paginationRef.current}
                searchPlaceholder={t('search', { param1: t('a_news') })}
            />

            <CreateNewsModal
                isVisible={mainContext?.articles.news.createNewsModalOpen}
                setIsVisible={mainContext?.articles.news.setCreateNewsModalOpen}
            />
        </div>
    );
});
