import { Alert, Button, Col, Form, Input, Row } from 'antd';
import Icon from 'Components/icons/icon';
import { PasswordLayout } from 'Components/password-layout';
import ValidatedFormItem from 'Components/validated-form-item/validated-form-item';
import { useFormValidation, useService } from 'Hooks';
import { FORGOT_PASSWORD } from 'Models/paths';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LoginSchema } from 'Schemas/LoginSchema';
import { AuthenticationService } from 'Services/AuthenticationService';
import './index.less';

export const Login = () => {
    const authenticationService = useService(AuthenticationService);
    const [isLoading, setIsLoading] = useState(false);
    const [loginErrorState, setLoginErrorState] = useState<string>();
    const [form] = Form.useForm();
    const [errors, validateForm] = useFormValidation(LoginSchema, form);
    const { t } = useTranslation();

    const submit = async (): Promise<void> => {
        setIsLoading(true);
        const formValues = form.getFieldsValue();

        try {
            if (!(await validateForm(formValues))) return;

            await authenticationService.initAccessToken(formValues.email, formValues.password);
            setIsLoading(false);
        } catch (error) {
            if ((error as any).response.status === 400)
                setLoginErrorState(t('incorrect_credentials'));
            else setLoginErrorState(t('error_occurred'));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <PasswordLayout
            className="Login"
            title={t('login_title')}
            subTitle={t('login_subtitle')}
            isLoading={isLoading}
        >
            <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                <Row>
                    {loginErrorState && (
                        <Col span={24}>
                            <Alert className="login-error" message={loginErrorState} type="error" />
                        </Col>
                    )}

                    <Col span={24}>
                        <ValidatedFormItem name="email" label={t('email')} errors={errors}>
                            <Input maxLength={50} prefix={<Icon iconName="Mail" />} />
                        </ValidatedFormItem>
                    </Col>

                    <Col span={24}>
                        <ValidatedFormItem name="password" label={t('password')} errors={errors}>
                            <Input
                                type="password"
                                maxLength={50}
                                prefix={<Icon iconName="Padlock" />}
                            />
                        </ValidatedFormItem>
                    </Col>

                    <Col className="Login__button" span={24}>
                        <Button type="primary" htmlType="button" onClick={submit}>
                            {t('login')}
                        </Button>
                    </Col>

                    <Col className="Login__link" span={24}>
                        <Link to={`/${FORGOT_PASSWORD}`}>{t('forgot_password')}</Link>
                    </Col>
                </Row>
            </Form>
        </PasswordLayout>
    );
};
