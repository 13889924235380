import { ArticleProxy } from "Api/Features/Articles/ArticleProxy";
import { ArticleDto } from "Api/Features/Articles/Dtos/ArticleDto";
import { CreateArticleRequestDto } from "Api/Features/Articles/Dtos/CreateArticleRequestDto";
import { GetArticlesRequestDto } from "Api/Features/Articles/Dtos/GetArticlesRequestDto";
import { GetArticlesResponseDto } from "Api/Features/Articles/Dtos/GetArticlesResponseDto";
import { UpdateArticleRequestDto } from "Api/Features/Articles/Dtos/UpdateArticleRequestDto";
import { inject } from "aurelia-dependency-injection";
import { ApiService } from "./ApiService";

@inject(ArticleProxy)
export class ArticleService extends ApiService {
  constructor(private readonly articleProxy: ArticleProxy) {
    super();
  }

  public async createArticle(
    request: CreateArticleRequestDto
  ): Promise<string> {
    const data: string = await this.articleProxy.createArticle(request);

    return data;
  }

  public async getArticle(id: string): Promise<ArticleDto | null> {
    const data: ArticleDto | null = await this.articleProxy.getArticle(id);

    return data;
  }

  public async getNews(
    request: GetArticlesRequestDto
  ): Promise<{ items: ArticleDto[]; totalItemCount: number }> {
    const data: GetArticlesResponseDto | null = await this.articleProxy.getNews(
      request
    );

    const nonNullItems = data?.items
      ? data.items.filter((item): item is ArticleDto => item !== null)
      : [];

    return {
      items: nonNullItems || [],
      totalItemCount: data?.totalItemCount || 0,
    };
  }

  public async getPressReleases(
    request: GetArticlesRequestDto
  ): Promise<{ items: ArticleDto[]; totalItemCount: number }> {
    const data: GetArticlesResponseDto | null =
      await this.articleProxy.getPressReleases(request);

    const nonNullItems = data?.items
      ? data.items.filter((item): item is ArticleDto => item !== null)
      : [];

    return {
      items: nonNullItems || [],
      totalItemCount: data?.totalItemCount || 0,
    };
  }

  public async updateArticle(
    id: string,
    request: UpdateArticleRequestDto
  ): Promise<void> {
    await this.articleProxy.updateArticle(id, request);
  }

  public async deleteArticle(id: string): Promise<void> {
    await this.articleProxy.deleteArticle(id);
  }
}
