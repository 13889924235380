import { SET_PASSWORD, FORGOT_PASSWORD, RESET_PASSWORD } from "Models/paths";
import React, { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";
import { SetResetPassword } from "./set-reset-password";
import { Login } from "./login";
import { ForgotPassword } from "./forgot-password";

export const AnonymousRoutes: FunctionComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path={`/${FORGOT_PASSWORD}`} element={<ForgotPassword />} />
      <Route path={`/${SET_PASSWORD}`} element={<SetResetPassword />} />
      <Route
        path={`/${RESET_PASSWORD}`}
        element={<SetResetPassword isEdition={true} />}
      />
    </Routes>
  );
};

export default AnonymousRoutes;
