import { inject } from "aurelia-dependency-injection";
import React from "react";
import {
  AuthenticationStore,
  AuthorizationStore,
  ToastStore,
  UserStore,
} from "Stores";
import ConfirmationModalStore from "Stores/ConfirmationModalStore";
import UrlStore from "Stores/UrlStore";

export interface GlobalStores {
  authenticationStore: AuthenticationStore;
  authorizationStore: AuthorizationStore;
  toastStore: ToastStore;
  urlStore: UrlStore;
  confirmationModalStore: ConfirmationModalStore;
  userStore: UserStore;
}

@inject(
  AuthenticationStore,
  AuthorizationStore,
  ToastStore,
  UrlStore,
  ConfirmationModalStore,
  UserStore
)
export class StoresContext {
  context: React.Context<GlobalStores>;

  constructor(
    authenticationStore: AuthenticationStore,
    authorizationStore: AuthorizationStore,
    toastStore: ToastStore,
    urlStore: UrlStore,
    confirmationModalStore: ConfirmationModalStore,
    userStore: UserStore
  ) {
    this.context = React.createContext({
      authenticationStore,
      authorizationStore,
      toastStore,
      urlStore,
      confirmationModalStore,
      userStore,
    });
  }
}
