import { Button, Col, Form, Input, Row } from 'antd';
import Icon from 'Components/icons/icon';
import { PasswordLayout } from 'Components/password-layout';
import ValidatedFormItem from 'Components/validated-form-item/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ForgotPasswordSchema } from 'Schemas/ForgotPasswordSchema';
import { AuthenticationService } from 'Services/AuthenticationService';
import './index.less';

export const ForgotPassword = observer(() => {
    const authenticationService = useService(AuthenticationService);
    const { toastStore } = useStores();
    const [emailSent, setEmailSent] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [errors, validateForm] = useFormValidation(ForgotPasswordSchema, form);
    const { t } = useTranslation();

    const passwordLayoutData = {
        topLink: emailSent
            ? undefined
            : {
                  label: t('back_to_login'),
                  url: '/',
              },
        title: emailSent ? t('reset_password_title_sent') : t('reset_password_title'),
        subTitle: emailSent ? t('reset_password_subtitle_sent') : t('reset_password_subtitle'),
    };

    const submit = async (): Promise<void> => {
        setIsLoading(true);
        const formValues = form.getFieldsValue();

        try {
            if (!(await validateForm(formValues))) return;

            await authenticationService.forgottenPassword(formValues.email);
            setIsLoading(false);
            setEmailSent(true);
        } catch (error) {
            if (!(error as any).treated) {
                toastStore.genericError();
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <PasswordLayout
            className="ForgotPassword"
            topLink={passwordLayoutData.topLink}
            title={passwordLayoutData.title}
            subTitle={passwordLayoutData.subTitle}
            isLoading={isLoading}
        >
            {!emailSent && (
                <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                    <Row>
                        <Col span={24}>
                            <ValidatedFormItem name="email" label={t('email')} errors={errors}>
                                <Input maxLength={50} prefix={<Icon iconName="Mail" />} />
                            </ValidatedFormItem>
                        </Col>

                        <Col className="ForgotPassword__button" span={24}>
                            <Button type="primary" htmlType="button" onClick={submit}>
                                {t('submit')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            )}

            {emailSent && (
                <Row>
                    <Col className="ForgotPassword__button" span={24}>
                        <Link className="ant-btn ant-btn-primary" to="/">
                            {t('login')}
                        </Link>
                    </Col>
                </Row>
            )}
        </PasswordLayout>
    );
});
