import { BaseModal } from "Components/base-modal";
import { BaseWindow } from "Components/base-window";
import { BottomButtons } from "Components/bottom-buttons";
import React, { FunctionComponent } from "react";
import "./index.less";

interface ModalAction {
  text: string;
  action: () => void;
}

export interface ConfirmationModalProps {
  id: string;
  title: string;
  message: string;
  positive: ModalAction;
  negative: ModalAction;
}

export const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = ({
  title,
  message,
  positive,
  negative,
}) => {
  const primaryButton = {
    label: positive.text,
    onClick: positive.action,
  };
  const secondaryButton = {
    label: negative.text,
    onClick: negative.action,
  };

  return (
    <BaseModal
      visible
      className="ConfirmationModal"
      onCancel={negative && negative.action}
    >
      <BaseWindow title={title}>
        <div className="ConfirmationModal__BaseWindow__message">{message}</div>

        <BottomButtons
          primaryButton={primaryButton}
          secondaryButton={secondaryButton}
        />
      </BaseWindow>
    </BaseModal>
  );
};
