// GENERATED FILE - DO NOT MODIFY
import { ArticleDto } from 'Api/Features/Articles/Dtos/ArticleDto';
import { CreateArticleRequestDto } from 'Api/Features/Articles/Dtos/CreateArticleRequestDto';
import { GetArticlesRequestDto } from 'Api/Features/Articles/Dtos/GetArticlesRequestDto';
import { GetArticlesResponseDto } from 'Api/Features/Articles/Dtos/GetArticlesResponseDto';
import { UpdateArticleRequestDto } from 'Api/Features/Articles/Dtos/UpdateArticleRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class ArticleProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async createArticle(request: CreateArticleRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/articles",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateArticleRequestDto | null, string>(uri, request);
        return data;
    }

    public async getArticle(id: string): Promise<ArticleDto | null> {
        const uri = this.buildUri(
            "/articles/{id}",
            { id: id },
            null
        );

        const data: ArticleDto | null = await this.httpClient.get<ArticleDto | null>(uri);
        return data;
    }

    public async getNews(request: GetArticlesRequestDto | null): Promise<GetArticlesResponseDto | null> {
        const uri = this.buildUri(
            "/news-articles",
            null,
            { ...request || undefined }
        );

        const data: GetArticlesResponseDto | null = await this.httpClient.get<GetArticlesResponseDto | null>(uri);
        return data;
    }

    public async getPressReleases(request: GetArticlesRequestDto | null): Promise<GetArticlesResponseDto | null> {
        const uri = this.buildUri(
            "/press-release-articles",
            null,
            { ...request || undefined }
        );

        const data: GetArticlesResponseDto | null = await this.httpClient.get<GetArticlesResponseDto | null>(uri);
        return data;
    }

    public async updateArticle(id: string, request: UpdateArticleRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/articles/{id}",
            { id: id },
            null
        );

        await this.httpClient.patch<UpdateArticleRequestDto | null, void>(uri, request);
    }

    public async deleteArticle(id: string): Promise<void> {
        const uri = this.buildUri(
            "/articles/{id}",
            { id: id },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }
}