import React, { FC, FunctionComponent, ReactElement } from "react";
import * as Icons from "./index";
import { SvgProps } from "./SvgProps";

type IconProps = { iconName: string } & SvgProps;

type Ic = IconProps & SvgProps;

const Icon: FunctionComponent<Ic> = ({ iconName, ...rest }) => {
  const getIconComponent = (iconName: string): ReactElement => {
    const IconName = iconName as keyof typeof Icons;
    const Icon: FC<SvgProps> = Icons[IconName];

    return <Icon {...rest} />;
  };

  return getIconComponent(iconName);
};

export default Icon;
