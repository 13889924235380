import * as yup from 'yup';
import { StringSchema } from 'yup';

export const SetResetPasswordSchema = yup.object({
    password: yup
        .string()
        .required('required')
        .min(6, 'password_restrictions')
        .max(50, 'password_restrictions'),
    passwordConfirmation: yup
        .string()
        .required('required')
        .when(
            'password',
            (password: string, schema: StringSchema) =>
                password && schema.equals([password], 'passwords_must_match')
        ),
});
