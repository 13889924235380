import { useStores } from "Hooks";
import { observer } from "mobx-react";
import { RETURN_URL } from "Models/Constants";
import { FunctionComponent } from "react";
import { useLocation } from "react-router-dom";
import AnonymousRoutes from "./anonymous";
import AuthenticatedRoutes from "./authenticated";

const Routes: FunctionComponent = observer(() => {
  const { urlStore, authenticationStore } = useStores();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const encodedReturnUrl = search.get(RETURN_URL);

  let returnUrl = "";
  if (encodedReturnUrl) {
    returnUrl = decodeURIComponent(encodedReturnUrl);
  }

  urlStore.setUrl(location.pathname);

  if (authenticationStore.loggedIn) {
    return <AuthenticatedRoutes returnUrl={returnUrl} />;
  } else {
    return <AnonymousRoutes />;
  }
});

export default Routes;
