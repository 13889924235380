import React, { FunctionComponent, useRef, useState } from 'react';
import { Upload } from 'antd';
import { UploadFile, UploadFileStatus } from 'antd/lib/upload/interface';
import './index.less';
import Icon from 'Components/icons/icon';
import { useTranslation } from 'react-i18next';

interface ImagePickerProps {
    allowMultiple?: boolean;
    images?: UploadFile[];
    setImagesDetails: (images: ImageDetails[] | undefined) => void;
    forIcon?: boolean;
    disableAddingImages?: boolean;
    disableRemovingImages?: boolean;
}

export interface ImageDetails {
    id: string;
    base64: string;
    isDeleted: boolean;
    fileName?: string;
    antUID?: string;
}

export const ImagePicker: FunctionComponent<ImagePickerProps> = ({
    allowMultiple,
    images,
    setImagesDetails,
    forIcon,
    disableAddingImages,
    disableRemovingImages,
}) => {
    const [fileList, setFileList] = useState<UploadFile[] | undefined>(images);
    const { t } = useTranslation();
    const imagesDetails = useRef<ImageDetails[]>(
        images?.map(
            (image: UploadFile<any>) => ({ id: image.uid, isDeleted: false } as ImageDetails)
        ) || []
    );
    const [showAddImage, setShowAddImage] = useState<boolean>(
        allowMultiple || images === undefined
    );

    const getBase64 = async (file: any): Promise<string | undefined> => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        return src?.substring(src?.lastIndexOf(',') + 1);
    };

    const handleOnChange = async (event: any): Promise<void> => {
        setFileList(event.fileList);
        if (event.file.status === ('done' as UploadFileStatus)) {
            const base = await getBase64(event.file);
            if (!allowMultiple) {
                imagesDetails.current = [
                    {
                        base64: base,
                        isDeleted: false,
                        fileName: event.file.name,
                        antUID: event.file.uid,
                    } as ImageDetails,
                ];
            } else {
                imagesDetails.current = [
                    ...imagesDetails.current,
                    {
                        base64: base,
                        isDeleted: false,
                        fileName: event.file.name,
                        antUID: event.file.uid,
                    } as ImageDetails,
                ];
            }
            setImagesDetails(imagesDetails.current);
        }
        if (event.file.status === ('removed' as UploadFileStatus)) {
            const image = images?.find((x) => x.uid === event.file.uid);
            if (image) {
                //image was an existing image from api model
                const imageDetails = imagesDetails.current?.find((x) => x.id === event.file.uid);
                imageDetails!.isDeleted = true;
            } else {
                //image was added in form and must now be ignored
                imagesDetails.current = imagesDetails.current?.filter(
                    (x) => x.antUID !== event.file.uid
                );
            }

            setImagesDetails(imagesDetails.current.length > 0 ? imagesDetails.current : undefined);
        }
        setShowAddImage(allowMultiple || event.fileList?.length === 0);
    };

    const onPreview = async (file: any): Promise<void> => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        if (imgWindow) imgWindow.document.write(image.outerHTML);
    };

    //The antd control requires to post on file select. We do not want this behavior so we must make a dummy request
    const dummyRequest = (request: any) => {
        setTimeout(() => {
            request.onSuccess('ok');
        }, 0);
    };

    return (
        <div className="ImagePicker">
            <Upload
                listType="picture-card"
                fileList={fileList}
                onChange={handleOnChange}
                onPreview={onPreview}
                customRequest={dummyRequest}
                className={forIcon ? 'for-icon' : ''}
                showUploadList={{ showRemoveIcon: !disableRemovingImages }}
            >
                {!disableAddingImages && (allowMultiple || showAddImage) && (
                    <div>
                        <p className="upload-icon">
                            <Icon iconName="Upload" />
                        </p>
                        <p className="ant-upload-text">{t('add_media')}</p>
                    </div>
                )}
            </Upload>
        </div>
    );
};
