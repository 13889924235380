import { Spin } from 'antd';
import Icon from 'Components/icons/icon';
import { SPIN_DELAY } from 'Models/Constants';
import moment from 'moment';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './index.less';

interface TopLink {
    label: string;
    url: string;
}

interface PasswordLayoutProps {
    className?: string;
    topLink?: TopLink;
    title: string;
    subTitle?: string;
    isLoading?: boolean;
}

export const PasswordLayout: FunctionComponent<PasswordLayoutProps> = ({
    className,
    topLink,
    title,
    subTitle,
    children,
    isLoading = false,
}) => {
    const { t } = useTranslation();

    return (
        <div className={`PasswordLayout ${className}`}>
            <div className="PasswordLayout--wrapper">
                <h1 className="PasswordLayout__title">
                    <Icon iconName="BatirenteLogo" />
                </h1>

                <Spin spinning={isLoading} delay={SPIN_DELAY}>
                    <section className="PasswordLayout__content">
                        {topLink && (
                            <Link className="PasswordLayout__content__link" to={topLink.url}>
                                <Icon iconName="LongArrowLeft" />
                                {topLink.label}
                            </Link>
                        )}

                        <h2 className="PasswordLayout__content__title">{title}</h2>
                        {subTitle && (
                            <div className="PasswordLayout__content__subTitle">{subTitle}</div>
                        )}

                        {children}
                    </section>
                </Spin>
            </div>

            <div className="PasswordLayout__footer">
                {t('copyright', { param1: t('app_name') })}
                {' ' + moment().year()}
            </div>
        </div>
    );
};
