import * as React from "react";
import { FunctionComponent } from "react";
import { SvgProps } from "./SvgProps";

const Chat: FunctionComponent<SvgProps> = ({
  fill = "currentColor",
  ...props
}) => {
  return (
    <span role="img" className="anticon">
      <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill}
          d="M8.90851 26.5335C10.3047 26.5335 13.1273 25.1775 15.2567 23.6607C22.3482 23.7913 27.6518 19.6931 27.6518 14.3092C27.6518 9.11607 22.4587 4.9375 15.99 4.9375C9.52123 4.9375 4.3382 9.11607 4.3382 14.3092C4.3382 17.6138 6.3873 20.5569 9.53128 22.0937C9.09936 22.9074 8.33596 24.0223 7.90404 24.6049C7.26119 25.4587 7.70315 26.5335 8.90851 26.5335ZM10.1239 24.4542C9.99333 24.5045 9.94311 24.4342 10.0335 24.3237C10.5458 23.7009 11.2991 22.7065 11.6708 22.0033C11.9721 21.4509 11.8717 20.9687 11.2288 20.6674C8.1652 19.231 6.3873 16.9308 6.3873 14.3092C6.3873 10.2712 10.6462 6.98661 15.99 6.98661C21.3337 6.98661 25.5927 10.2712 25.5927 14.3092C25.5927 18.3471 21.3337 21.6417 15.99 21.6417C15.8092 21.6417 15.5279 21.6317 15.1864 21.6317C14.7545 21.6217 14.4331 21.7522 14.0313 22.0636C12.8159 22.9676 11.0179 24.0926 10.1239 24.4542Z"
        />
      </svg>
    </span>
  );
};

export default Chat;
