import * as React from "react";
import { FunctionComponent } from "react";
import { SvgProps } from "./SvgProps";

const Add: FunctionComponent<SvgProps> = ({ fill = undefined, ...props }) => {
  return (
    <span role="img" className="anticon">
      <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill || "#ffffff"}
          d="M8.72767 16.75H14.8549V22.8772C14.8549 23.5 15.3672 24.0223 16 24.0223C16.6328 24.0223 17.1451 23.5 17.1451 22.8772V16.75H23.2723C23.8951 16.75 24.4174 16.2377 24.4174 15.6049C24.4174 14.9721 23.8951 14.4598 23.2723 14.4598H17.1451V8.33259C17.1451 7.70982 16.6328 7.1875 16 7.1875C15.3672 7.1875 14.8549 7.70982 14.8549 8.33259V14.4598H8.72767C8.1049 14.4598 7.58258 14.9721 7.58258 15.6049C7.58258 16.2377 8.1049 16.75 8.72767 16.75Z"
        />
      </svg>
    </span>
  );
};

export default Add;
