import { BaseModal } from 'Components/base-modal';
import { BaseWindow } from 'Components/base-window';
import { CreateEditAdministratorForm } from 'Components/create-edit-administrator-form';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react-lite';
import { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

interface CreateAdministratorModalProps {
    isVisible?: boolean;
    setIsVisible?: Dispatch<SetStateAction<boolean>>;
}

export const CreateAdministratorModal: FunctionComponent<CreateAdministratorModalProps> = observer(
    ({ isVisible, setIsVisible }) => {
        const { confirmationModalStore } = useStores();
        const { t } = useTranslation();

        const exit = async (success: boolean): Promise<boolean> => {
            if (!success) {
                if (
                    !(await confirmationModalStore.confirm({
                        title: t('addition_of', { param1: t('an_administrator') }),
                        message: t('add_confirm_message', { param1: t('an_administrator') }),
                        positiveText: t('confirm'),
                        negativeText: t('cancel'),
                    }))
                )
                    return false;
            }

            setIsVisible && setIsVisible(false);

            return true;
        };

        return (
            <BaseModal
                visible={isVisible}
                className="CreateAdministratorModal"
                onCancel={() => exit(false)}
            >
                <BaseWindow title={t('addition_of', { param1: t('an_administrator') })}>
                    <CreateEditAdministratorForm onCancel={exit} />
                </BaseWindow>
            </BaseModal>
        );
    }
);
