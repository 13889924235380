import { Col, Input, Row, Table, TablePaginationConfig, TableProps } from 'antd';
import { SortDirectionDto } from 'Api/Features/General/Dtos/SortDirectionDto';
import Icon from 'Components/icons/icon';
import debounce from 'lodash.debounce';
import { autorun } from 'mobx';
import { TABLE_DEBOUNCE_DELAY } from 'Models/Constants';
import React, { FunctionComponent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './index.less';

export const initialPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: 10,
    position: ['bottomRight', 'topRight'],
    showSizeChanger: true,
};

interface BaseTableProps extends TableProps<any> {
    fetch: (params: any) => Promise<void>;
    paginationRef: TablePaginationConfig;
    searchPlaceholder: string;
}

export const BaseTable: FunctionComponent<BaseTableProps> = ({
    fetch,
    paginationRef,
    searchPlaceholder,
    columns,
    dataSource,
    loading,
    pagination,
}) => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    let navigate = useNavigate();
    const { t } = useTranslation();

    const debounceSearchChange = useRef(
        debounce((params: { searchTerm?: string }) => {
            fetch({
                pagination: {
                    ...paginationRef,
                    current: 1,
                },
                searchTerm: params.searchTerm,
                sortColumn: null,
                sortDirection: null,
            });
        }, TABLE_DEBOUNCE_DELAY)
    );

    const onSearchChange = (value: any) => {
        setSearchTerm(value.target.value);

        const disposer = autorun(() => {
            debounceSearchChange.current({
                searchTerm: value.target.value,
            });
        });
        return (): void => {
            disposer();
        };
    };

    const handleOnRow = (row: any) => ({
        onClick: (): void => {
            navigate(row.id);
        },
    });

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        filter: any,
        sorter: any
    ): Promise<void> => {
        let sortDirection: SortDirectionDto | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirectionDto.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirectionDto.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }

        await fetch({
            pagination,
            searchTerm,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
        });

        paginationRef = pagination;
    };

    return (
        <Row className="BaseTable">
            <Col span={8} offset={16}>
                <Input
                    className="BaseTable__search"
                    onChange={onSearchChange}
                    prefix={<Icon iconName="MagnifyingGlass" />}
                    placeholder={searchPlaceholder}
                />
            </Col>

            <Col span={24}>
                <Table
                    className="ant-table-action-row"
                    locale={{
                        emptyText: <div className="BaseTable__empty">{t('table_no_results')}</div>,
                    }}
                    columns={columns}
                    dataSource={dataSource}
                    onChange={handleTableChange}
                    onRow={handleOnRow}
                    loading={loading}
                    pagination={pagination}
                    showSorterTooltip={false}
                    bordered={true}
                />
            </Col>
        </Row>
    );
};
