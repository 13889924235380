import { Button } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import './index.less';

interface ArticleFixedFooterProps {
    disabled: boolean;
    onClick: () => Promise<void>;
}

export const ArticleFixedFooter: FunctionComponent<ArticleFixedFooterProps> = ({
    disabled,
    onClick,
}) => {
    const { t } = useTranslation();

    return (
        <div className="ArticleFixedFooter">
            <Button
                className="ArticleFixedFooter__saveButton"
                type="primary"
                htmlType="button"
                onClick={onClick}
                disabled={disabled}
            >
                {t('save_changes')}
            </Button>
        </div>
    );
};
