import { action, observable } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { ConfirmationModalProps } from "Components/confirmation-modal/confirmation-modal";

interface ConfirmArgs {
  title: string;
  message: string;
  positiveText: string;
  negativeText?: string;
}

class ConfirmationModalStore {
  @observable currentModal: ConfirmationModalProps | null = null;

  @action
  confirm = (params: ConfirmArgs): Promise<boolean> => {
    return new Promise<boolean>(
      (resolve) =>
        (this.currentModal = {
          id: uuidv4(),
          positive: {
            text: params.positiveText,
            action: (): void => resolve(true),
          },
          negative: {
            text: params.negativeText ? params.negativeText : "",
            action: (): void => resolve(false),
          },
          ...params,
        })
    ).then((result) => {
      this.currentModal = null;
      return result;
    });
  };
}

export default ConfirmationModalStore;
