import { CreateUserRequestDto } from "Api/Features/Users/Dtos/CreateUserRequestDto";
import { ForgotPasswordRequestDto } from "Api/Features/Users/Dtos/ForgotPasswordRequestDto";
import { GetUsersRequestDto } from "Api/Features/Users/Dtos/GetUsersRequestDto";
import { GetUsersResponseDto } from "Api/Features/Users/Dtos/GetUsersResponseDto";
import { UpdateUserPasswordAnonymousRequestDto } from "Api/Features/Users/Dtos/UpdateUserPasswordAnonymousRequestDto";
import { UpdateUserRequestDto } from "Api/Features/Users/Dtos/UpdateUserRequestDto";
import { UserDetailsDto } from "Api/Features/Users/Dtos/UserDetailsDto";
import { UserDto } from "Api/Features/Users/Dtos/UserDto";
import { UserProxy } from "Api/Features/Users/UserProxy";
import { inject } from "aurelia-dependency-injection";
import { ApiService } from "./ApiService";

@inject(UserProxy)
export class UserService extends ApiService {
  constructor(private readonly userProxy: UserProxy) {
    super();
  }

  public async getUser(userId: string): Promise<UserDetailsDto | null> {
    const data: UserDetailsDto | null = await this.userProxy.getUser(userId);
    return data;
  }

  public async getUsers(
    request: GetUsersRequestDto
  ): Promise<{ items: UserDto[]; totalItemCount: number }> {
    const data: GetUsersResponseDto | null = await this.userProxy.getUsers(
      request
    );

    const nonNullItems = data?.items
      ? data.items.filter((item): item is UserDto => item !== null)
      : [];

    return {
      items: nonNullItems || [],
      totalItemCount: data?.totalItemCount || 0,
    };
  }

  public async createUser(request: CreateUserRequestDto): Promise<string> {
    const data: string = await this.userProxy.createUser(request);
    return data;
  }

  public async updateUser(
    userId: string,
    request: UpdateUserRequestDto
  ): Promise<void> {
    await this.userProxy.updateUser(userId, request);
  }

  public async deleteUser(userId: string): Promise<void> {
    await this.userProxy.deleteUser(userId, true);
  }

  public async forgotPassword(
    request: ForgotPasswordRequestDto | null
  ): Promise<void> {
    await this.userProxy.forgotPassword(request);
  }

  public async updateUserPasswordAnonymous(
    request: UpdateUserPasswordAnonymousRequestDto | null
  ): Promise<void> {
    await this.userProxy.updateUserPasswordAnonymous(request);
  }
}
