import * as React from "react";
import { FunctionComponent } from "react";
import { SvgProps } from "./SvgProps";

const MagnifyingGlass: FunctionComponent<SvgProps> = ({
  fill = "currentColor",
  ...props
}) => {
  return (
    <span role="img" className="anticon">
      <svg width="1em" height="1em" viewBox="0 0 25 25" fill="none" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill}
          d="M11.0717 17.5005C12.2695 17.5005 13.392 17.1389 14.3262 16.5212L17.6258 19.8284C17.8443 20.0393 18.123 20.1448 18.4244 20.1448C19.0497 20.1448 19.5092 19.6551 19.5092 19.0374C19.5092 18.7511 19.4113 18.4723 19.2003 18.2614L15.9233 14.9693C16.6013 14.005 17.0006 12.8373 17.0006 11.5717C17.0006 8.30967 14.3337 5.64282 11.0717 5.64282C7.81724 5.64282 5.14285 8.30967 5.14285 11.5717C5.14285 14.8337 7.80971 17.5005 11.0717 17.5005ZM11.0717 15.9185C8.68359 15.9185 6.72488 13.9598 6.72488 11.5717C6.72488 9.18356 8.68359 7.22485 11.0717 7.22485C13.4598 7.22485 15.4185 9.18356 15.4185 11.5717C15.4185 13.9598 13.4598 15.9185 11.0717 15.9185Z"
        />
      </svg>
    </span>
  );
};

export default MagnifyingGlass;
