import React, { FunctionComponent } from "react";
import "./index.less";

interface BaseWindowProps {
  title: string;
  small?: boolean;
}

export const BaseWindow: FunctionComponent<BaseWindowProps> = ({
  title,
  small = false,
  children,
}) => {
  return (
    <div className={`BaseWindow ${small && "BaseWindow--small"}`}>
      <h2 className="BaseWindow__title">{title}</h2>

      <div className="BaseWindow__content">{children}</div>
    </div>
  );
};
