import { FunctionComponent, useEffect } from "react";
import { autorun } from "mobx";
import { useStores } from "../../Hooks";
import { message } from "antd";
import "./index.less";
import { observer } from "mobx-react";

export const ToastContainer: FunctionComponent = observer(() => {
  const { toastStore } = useStores();

  useEffect(
    () =>
      autorun(() => {
        if (toastStore.currentToast) {
          message[toastStore.currentToast.type](
            toastStore.currentToast.message ||
              toastStore.currentToast.messageKey ||
              "",
            toastStore.currentToast.displayTime ?? 5
          );
        }
      }),
    [toastStore]
  );
  return null;
});
