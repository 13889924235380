import { Button } from "antd";
import React, { FunctionComponent, ReactNode } from "react";
import "./index.less";

interface ButtonProps {
  label: ReactNode;
  onClick: () => void;
}

export interface BottomButtonsProps {
  primaryButton: ButtonProps;
  secondaryButton: ButtonProps;
}

export const BottomButtons: FunctionComponent<BottomButtonsProps> = ({
  primaryButton,
  secondaryButton,
}) => {
  return (
    <div className="BottomButtons">
      <Button
        type="default"
        htmlType="button"
        onClick={secondaryButton?.onClick}
      >
        {secondaryButton?.label}
      </Button>

      <Button type="primary" htmlType="button" onClick={primaryButton.onClick}>
        {primaryButton.label}
      </Button>
    </div>
  );
};
