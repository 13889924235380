import { ArticleTypeDto } from 'Api/Features/Articles/Dtos/ArticleTypeDto';
import { MainContext } from 'Components/authenticated-layout';
import { BaseWindow } from 'Components/base-window';
import { CreateEditArticleForm } from 'Components/create-edit-article-form';
import { autorun } from 'mobx';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import './index.less';

export const NewsDetails = () => {
    const mainContext = useContext(MainContext);
    const articleId = useParams().id;
    const { t } = useTranslation();

    const initialValues = mainContext?.articles.articleData
        ? {
              titleFR: mainContext.articles.articleData.titleLocalizations?.fr,
              titleEN: mainContext.articles.articleData.titleLocalizations?.en,
              textFR: mainContext.articles.articleData.textLocalizations?.fr,
              textEN: mainContext.articles.articleData.textLocalizations?.en,
              date: moment(mainContext.articles.articleData.date),
              imageUrl: mainContext.articles.articleData.imageUrl,
          }
        : undefined;

    useEffect(() => {
        const disposer = autorun(() => {
            if (mainContext && articleId && mainContext.articles.articleData?.id !== articleId) {
                mainContext?.articles.fetchArticle && mainContext.articles.fetchArticle(articleId);
            }
        });

        return (): void => {
            disposer();
            if (mainContext) mainContext.articles.articleData = undefined;
        };
    }, [mainContext, articleId]);

    return (
        <div className="NewsDetails">
            <BaseWindow title={t('news_details_title')}>
                {initialValues && (
                    <CreateEditArticleForm
                        articleId={articleId}
                        articleType={ArticleTypeDto.News}
                        initialValues={initialValues}
                    />
                )}
            </BaseWindow>
        </div>
    );
};
