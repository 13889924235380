// GENERATED FILE - DO NOT MODIFY
import { CreateUserRequestDto } from 'Api/Features/Users/Dtos/CreateUserRequestDto';
import { ForgotPasswordRequestDto } from 'Api/Features/Users/Dtos/ForgotPasswordRequestDto';
import { GetUsersRequestDto } from 'Api/Features/Users/Dtos/GetUsersRequestDto';
import { GetUsersResponseDto } from 'Api/Features/Users/Dtos/GetUsersResponseDto';
import { ResendAccountConfirmationRequestDto } from 'Api/Features/Users/Dtos/ResendAccountConfirmationRequestDto';
import { UpdateUserPasswordAnonymousRequestDto } from 'Api/Features/Users/Dtos/UpdateUserPasswordAnonymousRequestDto';
import { UpdateUserPasswordRequestDto } from 'Api/Features/Users/Dtos/UpdateUserPasswordRequestDto';
import { UpdateUserRequestDto } from 'Api/Features/Users/Dtos/UpdateUserRequestDto';
import { UserDetailsDto } from 'Api/Features/Users/Dtos/UserDetailsDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class UserProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getUser(userId: string): Promise<UserDetailsDto | null> {
        const uri = this.buildUri(
            "/users/{userId}",
            { userId: userId },
            null
        );

        const data: UserDetailsDto | null = await this.httpClient.get<UserDetailsDto | null>(uri);
        return data;
    }

    public async getUsers(request: GetUsersRequestDto | null): Promise<GetUsersResponseDto | null> {
        const uri = this.buildUri(
            "/users",
            null,
            { ...request || undefined }
        );

        const data: GetUsersResponseDto | null = await this.httpClient.get<GetUsersResponseDto | null>(uri);
        return data;
    }

    public async createUser(request: CreateUserRequestDto | null): Promise<string> {
        const uri = this.buildUri(
            "/users",
            null,
            null
        );

        const data: string = await this.httpClient.post<CreateUserRequestDto | null, string>(uri, request);
        return data;
    }

    public async updateUser(userId: string, request: UpdateUserRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/users/{userId}",
            { userId: userId },
            null
        );

        await this.httpClient.patch<UpdateUserRequestDto | null, void>(uri, request);
    }

    public async deleteUser(userId: string, deleteManagerUser?: boolean): Promise<void> {
        const uri = this.buildUri(
            "/users/{userId}",
            { userId: userId },
            { deleteManagerUser: (deleteManagerUser === null || deleteManagerUser === undefined) ? deleteManagerUser : deleteManagerUser.toString() }
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async resendAccountConfirmation(request: ResendAccountConfirmationRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/resend-account-confirmation",
            null,
            null
        );

        await this.httpClient.post<ResendAccountConfirmationRequestDto | null, void>(uri, request);
    }

    public async forgotPassword(request: ForgotPasswordRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/users/forgot-password",
            null,
            null
        );

        await this.httpClient.post<ForgotPasswordRequestDto | null, void>(uri, request);
    }

    public async updateUserPasswordAnonymous(request: UpdateUserPasswordAnonymousRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/users/set-password",
            null,
            null
        );

        await this.httpClient.post<UpdateUserPasswordAnonymousRequestDto | null, void>(uri, request);
    }

    public async updateUserPassword(userId: string, request: UpdateUserPasswordRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/users/{userId}/change-password",
            { userId: userId },
            null
        );

        await this.httpClient.post<UpdateUserPasswordRequestDto | null, void>(uri, request);
    }
}