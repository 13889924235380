import { MainContext } from 'Components/authenticated-layout';
import { BaseWindow } from 'Components/base-window';
import { CreateEditAdministratorForm } from 'Components/create-edit-administrator-form';
import { autorun } from 'mobx';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import './index.less';

export const AdministratorDetails = () => {
    const mainContext = useContext(MainContext);
    const administratorId = useParams().id;
    const { t } = useTranslation();

    const initialValues = useMemo(
        () => ({
            firstName: mainContext?.administrators.administratorData?.firstName,
            lastName: mainContext?.administrators.administratorData?.lastName,
            email: mainContext?.administrators.administratorData?.email,
        }),
        [mainContext]
    );

    useEffect(() => {
        const disposer = autorun(() => {
            if (
                mainContext &&
                administratorId &&
                mainContext.administrators.administratorData?.id !== administratorId
            ) {
                mainContext?.administrators.fetchAdministrator &&
                    mainContext.administrators.fetchAdministrator(administratorId);
            }
        });

        return (): void => {
            disposer();
            if (mainContext) mainContext.administrators.administratorData = undefined;
        };
    }, [mainContext, administratorId]);

    return (
        <div className="AdministratorDetails">
            <BaseWindow small title={t('administrator_details_title')}>
                <CreateEditAdministratorForm
                    administratorId={administratorId}
                    initialValues={initialValues}
                />
            </BaseWindow>
        </div>
    );
};
