import * as React from "react";
import { FunctionComponent } from "react";
import { SvgProps } from "./SvgProps";

const Note: FunctionComponent<SvgProps> = ({
  fill = "currentColor",
  ...props
}) => {
  return (
    <span role="img" className="anticon">
      <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill}
          d="M10.6462 26.4933H21.3538C23.5134 26.4933 24.6283 25.3583 24.6283 23.1886V14.0681C24.6283 12.6618 24.4475 12.019 23.5737 11.125L18.3203 5.79129C17.4665 4.92746 16.7634 4.72656 15.4978 4.72656H10.6462C8.49664 4.72656 7.37164 5.86161 7.37164 8.04129V23.1886C7.37164 25.3583 8.49664 26.4933 10.6462 26.4933ZM10.817 24.4844C9.85267 24.4844 9.37053 23.9821 9.37053 23.058V8.16183C9.37053 7.24777 9.85267 6.73549 10.827 6.73549H15.0859V12.1998C15.0859 13.6562 15.7991 14.3594 17.2455 14.3594H22.6295V23.058C22.6295 23.9821 22.1473 24.4844 21.173 24.4844H10.817ZM17.4364 12.6016C17.0145 12.6016 16.8337 12.4208 16.8337 12.0089V6.99665L22.3582 12.6016H17.4364ZM19.5357 16.9911H12.2835C11.9018 16.9911 11.6306 17.2623 11.6306 17.6138C11.6306 17.9754 11.9018 18.2567 12.2835 18.2567H19.5357C19.8973 18.2567 20.1685 17.9754 20.1685 17.6138C20.1685 17.2623 19.8973 16.9911 19.5357 16.9911ZM19.5357 20.346H12.2835C11.9018 20.346 11.6306 20.6272 11.6306 20.9888C11.6306 21.3404 11.9018 21.6116 12.2835 21.6116H19.5357C19.8973 21.6116 20.1685 21.3404 20.1685 20.9888C20.1685 20.6272 19.8973 20.346 19.5357 20.346Z"
        />
      </svg>
    </span>
  );
};

export default Note;
