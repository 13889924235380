import * as React from "react";
import { FunctionComponent } from "react";
import { SvgProps } from "./SvgProps";

const Off: FunctionComponent<SvgProps> = ({
  fill = "currentColor",
  ...props
}) => {
  return (
    <span role="img" className="anticon">
      <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill}
          d="M15.995 16.9233C16.6214 16.9233 17.0291 16.4745 17.0291 15.8014V6.84642C17.0291 6.17326 16.6214 5.71429 15.995 5.71429C15.3786 5.71429 14.961 6.17326 14.961 6.84642V15.8014C14.961 16.4745 15.3786 16.9233 15.995 16.9233ZM15.995 27.4286C21.6325 27.4286 26.2857 22.6553 26.2857 16.8825C26.2857 13.8023 24.9534 11.1097 23.0941 9.31464C22.0601 8.27432 20.5985 9.67162 21.6624 10.7731C23.2433 12.303 24.2176 14.4653 24.2276 16.8825C24.2375 21.564 20.5587 25.3275 15.995 25.3275C11.4313 25.3275 7.78237 21.564 7.78237 16.8825C7.78237 14.4551 8.75676 12.2928 10.3376 10.7629C11.4015 9.65122 9.93994 8.26412 8.9059 9.30444C7.03667 11.0995 5.71429 13.8023 5.71429 16.8825C5.71429 22.6553 10.3675 27.4286 15.995 27.4286Z"
        />
      </svg>
    </span>
  );
};

export default Off;
