import Icon from 'Components/icons/icon';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import { ADMINISTRATORS, NEWS, PRESS_RELEASES } from 'Models/paths';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import './index.less';

interface ProfileData {
    id: string;
    name: string;
    email: string;
}

interface SideNavProps {
    profileData: ProfileData;
}

export const SideNav: FunctionComponent<SideNavProps> = observer(({ profileData }) => {
    const { confirmationModalStore, authenticationStore, userStore } = useStores();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const logout = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                title: t('logout'),
                message: t('logout_confirm_message'),
                positiveText: t('confirm'),
                negativeText: t('cancel'),
            }))
        )
            return;

        authenticationStore.clearSession();
        userStore.clearUserInfo();
        navigate('/');
    };

    return (
        <aside className="SideNav">
            <Link className="SideNav__logo" to="/">
                <Icon iconName="BatirenteLogo" />
            </Link>

            <div className="SideNav__profile">
                <div className="SideNav__profile__name">{profileData.name}</div>
                <div>{profileData.email}</div>

                <Link
                    className="SideNav__profile__link"
                    to={`/${ADMINISTRATORS}/${profileData.id}`}
                >
                    {t('see_profile')}
                </Link>
            </div>

            <NavLink className="SideNav__link" to={`/${ADMINISTRATORS}`}>
                <Icon iconName="Users" />
                {t('administrators_title')}
            </NavLink>
            <NavLink className="SideNav__link" to={`/${NEWS}`}>
                <Icon iconName="Note" />
                {t('news_title')}
            </NavLink>
            <NavLink className="SideNav__link" to={`/${PRESS_RELEASES}`}>
                <Icon iconName="Chat" />
                {t('press_releases_title')}
            </NavLink>

            <button className="SideNav__link SideNav__link--logout" onClick={logout}>
                <Icon iconName="Off" />
                {t('logout')}
            </button>
        </aside>
    );
});
