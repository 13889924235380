import { Button, Col, Form, Input, Row } from 'antd';
import Icon from 'Components/icons/icon';
import { PasswordLayout } from 'Components/password-layout';
import ValidatedFormItem from 'Components/validated-form-item/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { observer } from 'mobx-react';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { SetResetPasswordSchema } from 'Schemas/SetResetPasswordSchema';
import { UserService } from 'Services/UserService';
import './index.less';

interface SetResetPasswordProps {
    isEdition?: boolean;
}

export const SetResetPassword: FunctionComponent<SetResetPasswordProps> = observer(
    ({ isEdition = false }) => {
        const userService = useService(UserService);
        const { toastStore } = useStores();
        const [passwordUpdated, setPasswordUpdated] = useState<boolean>(false);
        const [isLoading, setIsLoading] = useState(false);
        let [searchParams] = useSearchParams();
        const [form] = Form.useForm();
        const [errors, validateForm] = useFormValidation(SetResetPasswordSchema, form);
        const { t } = useTranslation();

        const passwordLayoutData = {
            title: passwordUpdated
                ? t('set_password_title_edited')
                : isEdition
                ? t('set_password_title_edition')
                : t('set_password_title'),
            subTitle: passwordUpdated ? t('set_password_subtitle_edited') : undefined,
            inputLabel: isEdition ? t('new_password') : t('password'),
            inputConfirmationLabel: isEdition
                ? t('set_password_input_confirmation_label_edition')
                : t('set_password_input_confirmation_label'),
            buttonLabel: isEdition ? t('save') : t('save'),
        };

        const submit = async (): Promise<void> => {
            setIsLoading(true);
            const formValues = form.getFieldsValue();

            try {
                if (!(await validateForm(formValues))) return;

                const request = {
                    newPassword: formValues.password,
                    email: searchParams.get('email'),
                    token: searchParams.get('token'),
                };

                await userService.updateUserPasswordAnonymous(request);
                setIsLoading(false);
                setPasswordUpdated(true);
            } catch (error) {
                if (!(error as any).treated) {
                    toastStore.genericError();
                }
            } finally {
                setIsLoading(false);
            }
        };

        return (
            <PasswordLayout
                className="SetResetPassword"
                title={passwordLayoutData.title}
                subTitle={passwordLayoutData.subTitle}
                isLoading={isLoading}
            >
                {!passwordUpdated && (
                    <Form scrollToFirstError layout="vertical" onFinish={submit} form={form}>
                        <Row>
                            <Col span={24}>
                                <ValidatedFormItem
                                    name="password"
                                    label={passwordLayoutData.inputLabel}
                                    errors={errors}
                                >
                                    <Input
                                        type="password"
                                        maxLength={50}
                                        prefix={<Icon iconName="Padlock" />}
                                    />
                                </ValidatedFormItem>
                            </Col>

                            <Col span={24}>
                                <ValidatedFormItem
                                    name="passwordConfirmation"
                                    label={passwordLayoutData.inputConfirmationLabel}
                                    errors={errors}
                                >
                                    <Input
                                        type="password"
                                        maxLength={50}
                                        prefix={<Icon iconName="Padlock" />}
                                    />
                                </ValidatedFormItem>
                            </Col>

                            <Col className="SetResetPassword__button" span={24}>
                                <Button
                                    type="primary"
                                    className="positive"
                                    htmlType="button"
                                    onClick={submit}
                                >
                                    {passwordLayoutData.buttonLabel}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}

                {passwordUpdated && (
                    <Row>
                        <Col className="SetResetPassword__button" span={24}>
                            <Link className="ant-btn ant-btn-primary" to="/">
                                {t('set_password_go_to_login')}
                            </Link>
                        </Col>
                    </Row>
                )}
            </PasswordLayout>
        );
    }
);
