import * as React from "react";
import { FunctionComponent } from "react";
import { SvgProps } from "./SvgProps";

const Cross: FunctionComponent<SvgProps> = ({ fill = undefined, ...props }) => {
  return (
    <span role="img" className="anticon">
      <svg width="1em" height="1em" viewBox="0 0 15 15" fill="none" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill || "#ffffff"}
          d="M0.501162 12.4967C0.0792868 12.9185 0.0591975 13.6719 0.511206 14.1038C0.943126 14.5558 1.70652 14.5357 2.12839 14.1139L7.00005 9.24221L11.8717 14.1139C12.3036 14.5458 13.0469 14.5558 13.4788 14.1038C13.9308 13.6719 13.9208 12.9185 13.4889 12.4866L8.61723 7.61497L13.4889 2.75337C13.9208 2.3114 13.9308 1.5681 13.4788 1.13618C13.0469 0.684169 12.3036 0.694214 11.8717 1.12613L7.00005 5.99779L2.12839 1.12613C1.70652 0.704259 0.943126 0.684169 0.511206 1.13618C0.0591975 1.5681 0.0792868 2.32145 0.501162 2.74332L5.37281 7.61497L0.501162 12.4967Z"
        />
      </svg>
    </span>
  );
};

export default Cross;
