import { ColumnType, TablePaginationConfig } from 'antd/lib/table';
import { SortDirectionDto } from 'Api/Features/General/Dtos/SortDirectionDto';
import { GetUsersRequestDto } from 'Api/Features/Users/Dtos/GetUsersRequestDto';
import { GetUsersSortColumnDto } from 'Api/Features/Users/Dtos/GetUsersSortColumnDto';
import { MainContext } from 'Components/authenticated-layout';
import { BaseTable, initialPaginationState } from 'Components/base-table';
import { useService, useStores } from 'Hooks';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserService } from 'Services/UserService';
import './index.less';
import { CreateAdministratorModal } from './modals/create-administrator-modal';

interface AdministratorProps {
    key: string;
    id: string;
    name: string;
    email: string;
}

export const Administrators = observer(() => {
    const mainContext = useContext(MainContext);
    const userService = useService(UserService);
    const { toastStore } = useStores();
    const [administratorsList, setAdministratorsList] = useState<AdministratorProps[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const paginationRef = useRef(initialPaginationState);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const { t } = useTranslation();

    const columns: ColumnType<AdministratorProps>[] = [
        {
            key: GetUsersSortColumnDto.Name,
            title: t('name'),
            dataIndex: 'name',
            sorter: true,
        },
        {
            key: GetUsersSortColumnDto.Email,
            title: t('email'),
            dataIndex: 'email',
            sorter: true,
        },
    ];

    const fetch = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            searchTerm?: string;
            sortColumn: GetUsersSortColumnDto | null;
            sortDirection: SortDirectionDto | null;
        }) => {
            setIsLoading(true);

            try {
                const request: GetUsersRequestDto = {
                    pageSize: params.pagination.pageSize || 0,
                    page: (params.pagination.current || 1) - 1,
                    searchTerm: params.searchTerm,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                };

                const response = await userService.getUsers(request);

                const mapItems: AdministratorProps[] = response.items.map((item) => ({
                    key: item.id || '',
                    id: item.id || '',
                    name: `${item.firstName} ${item.lastName}`,
                    email: item.email || '',
                }));

                setAdministratorsList(mapItems);
                setPagination({
                    ...params.pagination,
                    total: response.totalItemCount,
                });
            } catch (error) {
                if (!(error as any).treated) {
                    toastStore.genericError();
                }
            } finally {
                setIsLoading(false);
            }
        },
        [userService, toastStore]
    );

    useEffect(() => {
        const disposer = autorun(() => {
            if (!mainContext?.administrators.createAdministratorModalOpen) {
                fetch({
                    pagination: {
                        ...paginationRef.current,
                        current: 1,
                    },
                    sortColumn: null,
                    sortDirection: null,
                });
            }
        });

        return (): void => {
            disposer();
        };
    }, [fetch, mainContext?.administrators.createAdministratorModalOpen]);

    return (
        <div className="Administrators">
            <BaseTable
                fetch={fetch}
                columns={columns}
                dataSource={administratorsList}
                loading={isLoading}
                pagination={pagination}
                paginationRef={paginationRef.current}
                searchPlaceholder={t('search', { param1: t('an_administrator') })}
            />

            <CreateAdministratorModal
                isVisible={mainContext?.administrators.createAdministratorModalOpen}
                setIsVisible={mainContext?.administrators.setCreateAdministratorModalOpen}
            />
        </div>
    );
});
