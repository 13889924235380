import * as React from "react";
import { FunctionComponent } from "react";
import { SvgProps } from "./SvgProps";

const BatirenteLogo: FunctionComponent<SvgProps> = ({
  fill = undefined,
  ...props
}) => {
  return (
    <span role="img" className="anticon">
      <svg
        width="2.3em"
        height="1em"
        viewBox="0 0 352 153"
        fill="none"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill || "#58595B"}
          d="M0.813965 112.733H28.6646C33.7541 112.733 36.0161 114.575 36.0161 119.394V123.363C36.0161 126.197 35.0264 128.606 30.2197 129.315C34.0368 129.74 36.1574 132.15 36.1574 136.118V140.512C36.1574 145.189 32.9058 147.315 26.544 147.315H0.813965V112.733ZM30.0784 120.386C30.0784 117.835 28.9474 117.41 26.544 117.41H6.46892V127.047H26.4026C28.9474 127.047 29.937 126.339 29.937 123.929V120.386H30.0784ZM30.3611 135.551C30.3611 132.291 29.3715 131.441 26.544 131.441H6.46892V142.638H26.4026C29.2301 142.638 30.2197 141.645 30.2197 138.527V135.551H30.3611Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill || "#58595B"}
          d="M68.5321 118.544H71.6423L65.2805 107.064H59.4842L53.1223 118.544H55.9498L40.2573 147.315H45.9123L50.2949 138.811H73.6215L78.0041 147.315H84.366L68.5321 118.544ZM52.8396 134.417L62.1703 116.701L71.5009 134.417H52.8396Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill || "#58595B"}
          d="M104.017 147.315H98.2203V117.41H82.5278V112.733H119.992V117.41H104.017V147.315Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill || "#58595B"}
          d="M124.657 112.733H130.454V147.315H124.657V112.733Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill || "#58595B"}
          d="M143.319 112.733H171.17C176.259 112.733 178.521 114.575 178.521 119.394V127.047C178.521 131.866 176.259 133.709 171.17 133.709H166.08L181.914 147.173H173.29L159.294 133.709H149.115V147.315H143.319V112.733ZM149.115 117.126V129.315H168.908C171.452 129.315 172.725 128.748 172.725 125.772V120.67C172.725 117.835 171.452 117.126 168.908 117.126H149.115Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill || "#58595B"}
          d="M189.265 112.733H222.488V117.41H195.061V127.047H211.885V131.583H195.061V142.638H222.912V147.315H189.407V112.733H189.265Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill || "#58595B"}
          d="M231.536 112.733H235.212L261.083 135.835C262.356 136.968 263.628 138.386 265.042 140.228C264.476 136.968 264.476 134.134 264.476 131.724V112.733H269.707V147.315H266.455L239.877 123.221C238.888 122.229 237.474 121.237 236.343 119.394C236.626 121.945 236.767 124.496 236.767 127.047V147.315H231.536V112.733Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill || "#58595B"}
          d="M296.003 147.315H290.206V117.41H274.373V112.733H311.837V117.41H296.003V147.315Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill || "#58595B"}
          d="M316.643 112.733H349.866V117.41H322.298V127.047H339.122V131.583H322.298V142.638H350.149V147.315H316.643V112.733Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill || "#B3BD35"}
          d="M178.038 31.1092L184.682 37.7705C186.661 39.7546 186.661 43.0144 184.682 44.9986L178.038 51.6598L185.106 58.7462C187.086 60.7304 190.337 60.7304 192.316 58.7462L206.03 44.9986C208.009 43.0144 208.009 39.7546 206.03 37.7705L192.316 24.0228C190.337 22.0386 187.086 22.0386 185.106 24.0228L184.682 24.448C184.682 24.448 184.682 24.448 184.682 24.5897L178.038 31.1092Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill || "#B3BD35"}
          d="M168.848 81.2808L182.561 67.5332C184.541 65.549 184.541 62.2892 182.561 60.305L175.917 53.6438L168.848 60.7302C166.869 62.7144 163.617 62.7144 161.638 60.7302L154.569 53.6438L147.925 60.305C145.946 62.2892 145.946 65.549 147.925 67.5332L161.638 81.2808C163.617 83.265 166.869 83.265 168.848 81.2808Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill || "#B3BD35"}
          d="M168.848 21.897L175.917 28.9834L182.561 22.3222C184.399 20.338 184.399 17.22 182.561 15.2358L168.848 1.48815C166.869 -0.496049 163.617 -0.496049 161.638 1.48815L147.925 15.2358C145.946 17.22 145.946 20.4797 147.925 22.4639L154.569 29.1251L161.638 22.0387C163.617 19.9128 166.869 19.9128 168.848 21.897Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill || "#808285"}
          d="M184.682 37.7705L178.038 31.1093L171.393 37.7705C169.555 39.7547 169.555 42.8727 171.393 44.8569L178.038 51.5181L184.682 44.8569C186.661 43.0144 186.661 39.7547 184.682 37.7705Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill || "#808285"}
          d="M168.848 60.8725L175.917 53.7861L168.848 46.6997C166.869 44.7155 163.617 44.7155 161.638 46.6997L154.569 53.7861L161.638 60.8725C163.617 62.8567 166.869 62.8567 168.848 60.8725Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill || "#808285"}
          d="M168.848 36.2118L169.272 35.7866C169.272 35.7866 169.272 35.7866 169.272 35.6448L175.917 28.9836L168.848 21.8972C166.869 19.913 163.617 19.913 161.638 21.8972L154.569 28.9836L161.638 36.07C163.617 38.1959 166.869 38.1959 168.848 36.2118Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill || "#808285"}
          d="M146.37 37.7705L153.014 31.1093L159.659 37.7705C161.497 39.7547 161.497 42.8727 159.659 44.8569L153.014 51.5181L146.37 44.8569C144.39 43.0144 144.39 39.7547 146.37 37.7705Z"
        />
      </svg>
    </span>
  );
};

export default BatirenteLogo;
