import * as React from "react";
import { FunctionComponent } from "react";
import { SvgProps } from "./SvgProps";

const Upload: FunctionComponent<SvgProps> = ({
  fill = "currentColor",
  ...props
}) => {
  return (
    <span role="img" className="anticon">
      <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill}
          d="M20 21.4146C20.6529 21.4146 21.1802 20.8873 21.1802 20.2595V8.19335L21.0798 6.3351L21.8582 7.25167L23.616 9.12248C23.8169 9.34849 24.1057 9.46149 24.3945 9.46149C24.9721 9.46149 25.4492 9.04715 25.4492 8.45703C25.4492 8.14313 25.3236 7.91713 25.1102 7.70368L20.904 3.64815C20.5901 3.34681 20.3139 3.24637 20 3.24637C19.6986 3.24637 19.4098 3.34681 19.1085 3.64815L14.8898 7.70368C14.6763 7.91713 14.5633 8.14313 14.5633 8.45703C14.5633 9.04715 15.0279 9.46149 15.6054 9.46149C15.8942 9.46149 16.1956 9.34849 16.3965 9.12248L18.1417 7.25167L18.9202 6.32254L18.8323 8.19335V20.2595C18.8323 20.8873 19.3596 21.4146 20 21.4146ZM9.2522 32.8404H30.7352C33.4598 32.8404 34.8786 31.4341 34.8786 28.7472V21.3769C34.8786 20.046 34.753 19.3178 34.1127 18.5519L30.459 14.0569C28.789 12.0103 28.1612 11.5206 25.8635 11.5206H23.503V13.6049H25.9263C26.7927 13.6049 27.3451 13.7807 28.0608 14.6596L31.9405 19.4685C32.38 20.0335 32.2042 20.272 31.5639 20.272H23.6914C23.0385 20.272 22.7371 20.7366 22.7371 21.2263V21.2765C22.7371 22.6325 21.6824 24.0764 20 24.0764C18.3049 24.0764 17.2502 22.6325 17.2502 21.2765V21.2263C17.2502 20.7366 16.9615 20.272 16.296 20.272H8.43607C7.78317 20.272 7.64505 19.9958 8.0594 19.4685L11.914 14.6973C12.6548 13.7932 13.1947 13.6049 14.0736 13.6049H16.4969V11.5206H14.1364C11.8387 11.5206 11.2235 11.9978 9.51587 14.082L5.87469 18.5519C5.25945 19.3052 5.12134 20.046 5.12134 21.3769V28.7472C5.12134 31.4341 6.54014 32.8404 9.2522 32.8404ZM9.37776 30.3292C8.22262 30.3292 7.59483 29.7266 7.59483 28.5212V22.4819H15.1158C15.5175 24.7168 17.4637 26.3742 20 26.3742C22.5362 26.3742 24.4824 24.7168 24.8842 22.4819H32.4051V28.5212C32.4051 29.7266 31.7648 30.3292 30.6096 30.3292H9.37776Z"
        />
      </svg>
    </span>
  );
};

export default Upload;
