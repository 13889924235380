import { ConfigProvider } from "antd";
import { ConfirmationModalContainer } from "Components/confirmation-modal/confirmation-modal-container";
import { ToastContainer } from "Components/toast-container/toast-container";
import { useService } from "Hooks";
import { FunctionComponent, ReactNode, useLayoutEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "Routes";
import { AuthenticationService } from "Services/AuthenticationService";

const App: FunctionComponent = () => {
  const authenticationService = useService(AuthenticationService);
  useLayoutEffect(() => {
    authenticationService.installInterceptors();
  }, [authenticationService]);

  return (
    <>
      <BrowserRouter>
        <ConfigProvider renderEmpty={(): ReactNode => <div>empty</div>}>
          <Routes />
        </ConfigProvider>
      </BrowserRouter>

      <ConfirmationModalContainer />
      <ToastContainer />
    </>
  );
};

export default App;
