import * as React from "react";
import { FunctionComponent } from "react";
import { SvgProps } from "./SvgProps";

const Padlock: FunctionComponent<SvgProps> = ({
  fill = "currentColor",
  ...props
}) => {
  return (
    <span role="img" className="anticon">
      <svg width="1em" height="1em" viewBox="0 0 25 25" fill="none" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill}
          d="M8.45923 19.5563H15.5407C16.7084 19.5563 17.2809 18.9762 17.2809 17.7106V12.3543C17.2809 11.2318 16.8289 10.6517 15.9098 10.5312V8.7759C15.9098 5.91317 14.0039 4.53455 12 4.53455C9.99606 4.53455 8.09009 5.91317 8.09009 8.7759V10.5312C7.171 10.6517 6.71899 11.2318 6.71899 12.3543V17.7106C6.71899 18.9762 7.29154 19.5563 8.45923 19.5563ZM9.53652 8.64029C9.53652 6.88499 10.6515 5.92071 12 5.92071C13.3409 5.92071 14.4634 6.88499 14.4634 8.64029V10.5086H9.53652V8.64029ZM8.7003 18.1927C8.36883 18.1927 8.20309 18.0345 8.20309 17.6428V12.4221C8.20309 12.0228 8.36883 11.8797 8.7003 11.8797H15.3072C15.6386 11.8797 15.7968 12.0228 15.7968 12.4221V17.6428C15.7968 18.0345 15.6386 18.1927 15.3072 18.1927H8.7003Z"
        />
      </svg>
    </span>
  );
};

export default Padlock;
