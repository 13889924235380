import * as React from "react";
import { FunctionComponent } from "react";
import { SvgProps } from "./SvgProps";

const Users: FunctionComponent<SvgProps> = ({
  fill = "currentColor",
  ...props
}) => {
  return (
    <span role="img" className="anticon">
      <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill}
          d="M21.2534 15.5145C23.4531 15.5145 25.3114 13.5658 25.3114 11.0446C25.3114 8.58371 23.4431 6.69531 21.2534 6.69531C19.0737 6.69531 17.1953 8.61384 17.2054 11.0647C17.2054 13.5658 19.0536 15.5145 21.2534 15.5145ZM9.64176 15.7455C11.5603 15.7455 13.1775 14.0379 13.1775 11.8482C13.1775 9.69866 11.5402 8.06138 9.64176 8.06138C7.74332 8.06138 6.10604 9.72879 6.11609 11.8683C6.11609 14.0379 7.73328 15.7455 9.64176 15.7455ZM4.10716 24.8661H11.6406C10.5659 23.3292 11.721 20.2958 13.9911 18.5078C12.8862 17.7946 11.4498 17.2723 9.64176 17.2723C5.1518 17.2723 2.2489 20.577 2.2489 23.2991C2.2489 24.2935 2.75113 24.8661 4.10716 24.8661ZM14.9956 24.856H27.5112C29.1685 24.856 29.7411 24.3538 29.7411 23.4297C29.7411 20.8482 26.4565 17.2924 21.2534 17.2924C16.0402 17.2924 12.7656 20.8482 12.7656 23.4297C12.7656 24.3538 13.3382 24.856 14.9956 24.856Z"
        />
      </svg>
    </span>
  );
};

export default Users;
