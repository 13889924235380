import * as React from "react";
import { FunctionComponent } from "react";
import { SvgProps } from "./SvgProps";

const Mail: FunctionComponent<SvgProps> = ({
  fill = "currentColor",
  ...props
}) => {
  return (
    <span role="img" className="anticon">
      <svg width="1em" height="1em" viewBox="0 0 25 25" fill="none" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill}
          d="M6.01099 18.8331H18.1625C19.6165 18.8331 20.4602 17.9894 20.4602 16.3772V8.05272C20.4602 6.44055 19.6089 5.5968 17.9817 5.5968H5.83018C4.37622 5.5968 3.53247 6.43302 3.53247 8.05272V16.3772C3.53247 17.9894 4.38375 18.8331 6.01099 18.8331ZM11.1488 12.4749L5.66445 7.05076C5.76991 7.02816 5.89045 7.0131 6.01852 7.0131H17.9742C18.1098 7.0131 18.2303 7.02816 18.3433 7.0583L12.8665 12.4749C12.55 12.7837 12.2864 12.9193 12.0076 12.9193C11.7289 12.9193 11.4652 12.7762 11.1488 12.4749ZM4.94123 8.15819L9.03945 12.1886L4.94123 16.2416V8.15819ZM14.9758 12.1886L19.0514 8.18079V16.2265L14.9758 12.1886ZM6.01852 17.4093C5.88292 17.4093 5.75485 17.3942 5.64185 17.3716L9.95853 13.0926L10.3653 13.4994C10.9153 14.0343 11.4426 14.2603 12.0076 14.2603C12.5651 14.2603 13.1 14.0343 13.6424 13.4994L14.0567 13.0926L18.3659 17.3641C18.2454 17.3942 18.1173 17.4093 17.9742 17.4093H6.01852Z"
        />
      </svg>
    </span>
  );
};

export default Mail;
