import { Button, Col, Divider, Form, Input, Spin } from 'antd';
import Row from 'antd/lib/grid/row';
import { CreateUserRequestDto } from 'Api/Features/Users/Dtos/CreateUserRequestDto';
import { ManagementRoleNameDto } from 'Api/Features/Users/Dtos/ManagementRoleNameDto';
import { UpdateUserRequestDto } from 'Api/Features/Users/Dtos/UpdateUserRequestDto';
import { formGutter, MainContext } from 'Components/authenticated-layout';
import { BottomButtons } from 'Components/bottom-buttons';
import Icon from 'Components/icons/icon';
import ValidatedFormItem from 'Components/validated-form-item/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import lodash from 'lodash';
import { observer } from 'mobx-react';
import { SPIN_DELAY } from 'Models/Constants';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateEditAdministratorSchema } from 'Schemas/CreateEditAdministratorSchema';
import { UserService } from 'Services/UserService';
import './index.less';

interface CreateEditAdministratorFormProps {
    administratorId?: string;
    initialValues?: CreateUserRequestDto;
    onCancel?: (success: boolean) => Promise<boolean>;
}

export const CreateEditAdministratorForm: FunctionComponent<CreateEditAdministratorFormProps> =
    observer(({ administratorId, initialValues, onCancel }) => {
        const mainContext = useContext(MainContext);
        const userService = useService(UserService);
        const { toastStore } = useStores();
        const [isLoading, setIsLoading] = useState(false);
        const [valuesHaveChanged, setValuesHaveChanged] = useState(false);
        const [form] = Form.useForm();
        const [errors, validateForm, resetErrors] = useFormValidation(
            CreateEditAdministratorSchema,
            form
        );
        const { t } = useTranslation();

        const isEdition = !!initialValues;

        const dismiss = async (success: boolean): Promise<void> => {
            let exit = false;

            if (onCancel) {
                exit = await onCancel(success);
            }

            if (exit) {
                form.resetFields();
                resetErrors();
            }
        };

        const handleOnChange = () => {
            if (lodash.isEqual(initialValues, form.getFieldsValue())) {
                setValuesHaveChanged(false);
            } else {
                setValuesHaveChanged(true);
            }
        };

        const submit = async (): Promise<void> => {
            if (isEdition) {
                mainContext?.setIsLoading(true);
            } else {
                setIsLoading(true);
            }

            const formValues = form.getFieldsValue();

            try {
                if (!(await validateForm(formValues))) return;

                if (isEdition && administratorId) {
                    const request: UpdateUserRequestDto = {
                        firstName: formValues.firstName,
                        lastName: formValues.lastName,
                    };

                    await userService.updateUser(administratorId, request);

                    mainContext?.administrators.fetchAdministrator &&
                        mainContext.administrators.fetchAdministrator(administratorId);

                    initialValues.firstName = request.firstName;
                    initialValues.lastName = request.lastName;

                    handleOnChange();

                    toastStore.toast({
                        type: 'success',
                        messageKey: t('changes_saved'),
                    });
                } else {
                    const request: CreateUserRequestDto = {
                        firstName: formValues.firstName,
                        lastName: formValues.lastName,
                        email: formValues.email,
                        managementRoles: [{ name: ManagementRoleNameDto.Administrator }],
                    };

                    const createdId = await userService.createUser(request);

                    if (createdId) {
                        dismiss(true);

                        toastStore.toast({
                            type: 'success',
                            messageKey: t('administrator_created'),
                        });
                    }
                }
            } catch (error) {
                if (!(error as any).treated) {
                    toastStore.genericError();
                }
            } finally {
                if (isEdition) {
                    mainContext?.setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            }
        };

        const primaryButton = {
            label: (
                <>
                    <Icon iconName="Add" />
                    {t('add', { param1: t('an_administrator') })}
                </>
            ),
            onClick: submit,
        };
        const secondaryButton = {
            label: t('cancel'),
            onClick: () => dismiss(false),
        };

        useEffect(() => {
            if (isEdition && initialValues) {
                form.setFieldsValue({
                    firstName: initialValues.firstName,
                    lastName: initialValues.lastName,
                    email: initialValues.email,
                });
            }
        }, [form, isEdition, initialValues]);

        return (
            <Spin spinning={isLoading} delay={SPIN_DELAY}>
                <Form
                    className="CreateEditAdministratorForm"
                    scrollToFirstError
                    layout="vertical"
                    onFinish={submit}
                    form={form}
                >
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <ValidatedFormItem
                                name="firstName"
                                label={t('first_name')}
                                errors={errors}
                            >
                                <Input onChange={handleOnChange} maxLength={50} />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={12}>
                            <ValidatedFormItem name="lastName" label={t('name')} errors={errors}>
                                <Input onChange={handleOnChange} maxLength={50} />
                            </ValidatedFormItem>
                        </Col>

                        {!isEdition && (
                            <>
                                <Col span={24}>
                                    <ValidatedFormItem
                                        name="email"
                                        label={t('email')}
                                        errors={errors}
                                    >
                                        <Input maxLength={50} />
                                    </ValidatedFormItem>
                                </Col>

                                {secondaryButton && (
                                    <Col span={24}>
                                        <BottomButtons
                                            primaryButton={primaryButton}
                                            secondaryButton={secondaryButton}
                                        />
                                    </Col>
                                )}
                            </>
                        )}

                        {isEdition && (
                            <>
                                <Divider />

                                <Col span={12} className="AdministratorDetails__disabledEmail">
                                    <ValidatedFormItem name="email" label={t('email')}>
                                        <Input maxLength={50} disabled />
                                    </ValidatedFormItem>
                                </Col>

                                <Col span={12}>
                                    <Button
                                        className="AdministratorDetails__saveButton"
                                        type="primary"
                                        htmlType="submit"
                                        disabled={!valuesHaveChanged}
                                    >
                                        <Icon iconName="Add" />
                                        {t('save_changes')}
                                    </Button>
                                </Col>
                            </>
                        )}
                    </Row>
                </Form>
            </Spin>
        );
    });
