import { Button } from "antd";
import { FunctionComponent, ReactNode } from "react";
import { Link } from "react-router-dom";
import Icon from "../icons/icon";
import "./index.less";

interface ButtonProps {
  label: ReactNode;
  primary: boolean;
  onClick: () => void;
}

interface HeaderProps {
  data: {
    title: string;
    backLink?: string;
  };
  button?: ButtonProps;
}

export const Header: FunctionComponent<HeaderProps> = ({ data, button }) => {
  return (
    <header className="Header">
      <div className="Header--wrapper">
        {!data.backLink && <h1 className="Header__title">{data.title}</h1>}

        {data.backLink && (
          <Link className="Header__backLink" to={data.backLink}>
            <Icon iconName="ArrowLeft" />
            {data.title}
          </Link>
        )}

        {button && (
          <Button
            type={button.primary ? "primary" : "default"}
            className="Header__button"
            htmlType="button"
            onClick={button.onClick}
          >
            {button.label}
          </Button>
        )}
      </div>
    </header>
  );
};
