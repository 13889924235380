import { Layout, Spin } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { ArticleDto } from 'Api/Features/Articles/Dtos/ArticleDto';
import { ArticleTypeDto } from 'Api/Features/Articles/Dtos/ArticleTypeDto';
import { UserDetailsDto } from 'Api/Features/Users/Dtos/UserDetailsDto';
import { Header } from 'Components/header';
import Icon from 'Components/icons/icon';
import { SideNav } from 'Components/side-nav';
import { useService, useStores } from 'Hooks';
import { observer } from 'mobx-react';
import { SPIN_DELAY } from 'Models/Constants';
import { ADMINISTRATORS, NEWS, PRESS_RELEASES } from 'Models/paths';
import {
    createContext,
    Dispatch,
    FunctionComponent,
    ReactNode,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ArticleService } from 'Services/ArticleService';
import { UserService } from 'Services/UserService';
import './index.less';

export const formGutter: [Gutter, Gutter] = [40, 0];
const { Content } = Layout;

interface AdministratorsProps {
    createAdministratorModalOpen: boolean;
    setCreateAdministratorModalOpen: Dispatch<SetStateAction<boolean>>;
    fetchAdministrator?: (administratorId?: string) => Promise<void>;
    administratorData?: UserDetailsDto;
}

interface NewsProps {
    createNewsModalOpen: boolean;
    setCreateNewsModalOpen: Dispatch<SetStateAction<boolean>>;
}

interface PressReleasesProps {
    createPressReleaseModalOpen: boolean;
    setCreatePressReleaseModalOpen: Dispatch<SetStateAction<boolean>>;
}
interface ArticlesProps {
    fetchArticle?: (articleId?: string) => Promise<void>;
    articleData?: ArticleDto;
    news: NewsProps;
    pressReleases: PressReleasesProps;
}

interface MainContextProps {
    administrators: AdministratorsProps;
    articles: ArticlesProps;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const MainContext = createContext<MainContextProps | undefined>(undefined);

interface HeaderButton {
    label: ReactNode;
    primary: boolean;
    onClick: () => void;
}

interface LocationDataProps {
    name: string;
    path?: string;
    button?: HeaderButton;
}

export const AuthenticatedLayout: FunctionComponent = observer(() => {
    const userService = useService(UserService);
    const articleService = useService(ArticleService);
    const { userStore, toastStore, confirmationModalStore } = useStores();
    const [isLoading, setIsLoading] = useState(false);
    const [createAdministratorModalOpen, setCreateAdministratorModalOpen] = useState(false);
    const [createNewsModalOpen, setCreateNewsModalOpen] = useState(false);
    const [createPressReleaseModalOpen, setCreatePressReleaseModalOpen] = useState(false);
    const [mainContext, setMainContext] = useState<MainContextProps>({
        administrators: {
            createAdministratorModalOpen,
            setCreateAdministratorModalOpen,
        },
        articles: {
            news: { createNewsModalOpen, setCreateNewsModalOpen },
            pressReleases: {
                createPressReleaseModalOpen,
                setCreatePressReleaseModalOpen,
            },
        },
        setIsLoading,
    });
    const location = useLocation();
    const locationArray = location.pathname.split('/');
    const locationData: LocationDataProps = { name: '' };
    const navigate = useNavigate();
    const { t } = useTranslation();

    const fetchAdministrator = useCallback(
        async (administratorId) => {
            setIsLoading(true);
            let response: UserDetailsDto | undefined | null = undefined;
            try {
                if (administratorId) response = await userService.getUser(administratorId);

                if (response) {
                    setMainContext((previousValues) => ({
                        ...previousValues,
                        administrators: {
                            ...previousValues.administrators,
                            administratorData: response || undefined,
                        },
                    }));
                }
            } catch (error) {
                if (!(error as any).treated) {
                    toastStore.genericError();
                }
            } finally {
                setIsLoading(false);
            }
        },
        [userService, toastStore]
    );

    const deleteUser = async (): Promise<void> => {
        if (
            !(await confirmationModalStore.confirm({
                title: t('deletion_of', { param1: t('an_administrator') }),
                message: t('deletion_confirm_message', { param1: t('this_administrator') }),
                positiveText: t('confirm'),
                negativeText: t('cancel'),
            }))
        )
            return;

        try {
            setIsLoading(true);

            if (mainContext?.administrators.administratorData?.id)
                await userService.deleteUser(mainContext.administrators.administratorData.id);

            navigate(`/${ADMINISTRATORS}`);
        } catch (error) {
            if (!(error as any).treated) {
                toastStore.genericError();
            }
        } finally {
            setIsLoading(false);
        }
    };

    const deleteArticle = async (articleType: ArticleTypeDto): Promise<void> => {
        const articleTypeText =
            articleType === ArticleTypeDto.News
                ? ['a_news', 'this_news']
                : ['a_press_release', 'this_press_release'];
        const articleTypeUrl = articleType === ArticleTypeDto.News ? NEWS : PRESS_RELEASES;

        if (
            !(await confirmationModalStore.confirm({
                title: t('deletion_of', { param1: t(articleTypeText[0]) }),
                message: t('deletion_confirm_message', { param1: t(articleTypeText[1]) }),
                positiveText: t('confirm'),
                negativeText: t('cancel'),
            }))
        )
            return;

        try {
            setIsLoading(true);

            if (mainContext?.articles.articleData?.id)
                await articleService.deleteArticle(mainContext.articles.articleData.id);

            navigate(`/${articleTypeUrl}`);
        } catch (error) {
            if (!(error as any).treated) {
                toastStore.genericError();
            }
        } finally {
            setIsLoading(false);
        }
    };

    const fetchArticle = useCallback(
        async (articleId) => {
            setIsLoading(true);
            let response: ArticleDto | undefined | null = undefined;

            try {
                if (articleId) response = await articleService.getArticle(articleId);

                if (response) {
                    setMainContext((previousValues) => ({
                        ...previousValues,
                        articles: {
                            ...previousValues.articles,
                            articleData: response || undefined,
                        },
                    }));
                }
            } catch (error) {
                if (!(error as any).treated) {
                    toastStore.genericError();
                }
            } finally {
                setIsLoading(false);
            }
        },
        [articleService, toastStore]
    );

    switch (locationArray[1]) {
        case ADMINISTRATORS:
            locationData.name = t('administrators_title');
            locationData.button = {
                label: (
                    <>
                        <Icon iconName="Add" />
                        {t('add', { param1: t('an_administrator') })}
                    </>
                ),
                primary: true,
                onClick: () => setCreateAdministratorModalOpen(true),
            };

            if (locationArray.length > 2) {
                locationData.path = `/${ADMINISTRATORS}`;
                locationData.button =
                    userStore.userInfo?.id &&
                    mainContext?.administrators.administratorData?.id &&
                    userStore.userInfo.id !== mainContext.administrators.administratorData.id
                        ? {
                              label: t('delete'),
                              primary: false,
                              onClick: deleteUser,
                          }
                        : undefined;
            }
            break;
        case NEWS:
            locationData.name = t('news_title');
            locationData.button = {
                label: (
                    <>
                        <Icon iconName="Add" />
                        {t('add', { param1: t('a_news') })}
                    </>
                ),
                primary: true,
                onClick: () => setCreateNewsModalOpen(true),
            };

            if (locationArray.length > 2) {
                locationData.path = `/${NEWS}`;
                locationData.button = {
                    label: t('delete'),
                    primary: false,
                    onClick: () => deleteArticle(ArticleTypeDto.News),
                };
            }
            break;
        case PRESS_RELEASES:
            locationData.name = t('press_releases_title');
            locationData.button = {
                label: (
                    <>
                        <Icon iconName="Add" />
                        {t('add', { param1: t('a_press_release') })}
                    </>
                ),
                primary: true,
                onClick: () => setCreatePressReleaseModalOpen(true),
            };

            if (locationArray.length > 2) {
                locationData.path = `/${PRESS_RELEASES}`;
                locationData.button = {
                    label: t('delete'),
                    primary: false,
                    onClick: () => deleteArticle(ArticleTypeDto.PressRelease),
                };
            }
            break;
    }

    const profileData = {
        id: `${userStore.userInfo?.id}`,
        name: `${userStore.userInfo?.firstName} ${userStore.userInfo?.lastName}`,
        email: `${userStore.userInfo?.email}`,
    };

    const headerData = {
        data: {
            title: locationData.name,
            backLink: locationData.path,
        },
        button: locationData.button,
    };

    useEffect(() => {
        setMainContext((previousValues) => ({
            ...previousValues,
            administrators: {
                ...previousValues.administrators,
                createAdministratorModalOpen,
                fetchAdministrator,
            },
            articles: {
                ...previousValues.articles,
                fetchArticle,
                news: {
                    ...previousValues.articles.news,
                    createNewsModalOpen,
                },
                pressReleases: {
                    ...previousValues.articles.pressReleases,
                    createPressReleaseModalOpen,
                },
            },
        }));
    }, [
        fetchAdministrator,
        fetchArticle,
        createAdministratorModalOpen,
        createNewsModalOpen,
        createPressReleaseModalOpen,
    ]);

    return (
        <MainContext.Provider value={mainContext}>
            <div className={`AuthenticatedLayout page-${locationArray[1]}`}>
                <SideNav profileData={profileData} />
                <Header data={headerData.data} button={headerData.button} />

                <Content className="App__main">
                    <Spin spinning={isLoading} delay={SPIN_DELAY}>
                        <Outlet />
                    </Spin>
                </Content>
            </div>
        </MainContext.Provider>
    );
});
