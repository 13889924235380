import { Col, ConfigProvider, DatePicker, Divider, Form, Input, Spin } from 'antd';
import Row from 'antd/lib/grid/row';
import locale from 'antd/lib/locale/fr_CA';
import { UploadFile } from 'antd/lib/upload/interface';
import { ArticleTypeDto } from 'Api/Features/Articles/Dtos/ArticleTypeDto';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { ArticleFixedFooter } from 'Components/article-fixed-footer';
import { formGutter, MainContext } from 'Components/authenticated-layout';
import { BottomButtons } from 'Components/bottom-buttons';
import Icon from 'Components/icons/icon';
import { ImageDetails, ImagePicker } from 'Components/image-picker';
import ValidatedFormItem from 'Components/validated-form-item/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import lodash from 'lodash';
import { observer } from 'mobx-react';
import { DISPLAY_DATE_FORMAT, SPIN_DELAY } from 'Models/Constants';
import moment from 'moment';
import 'moment/locale/fr-ca';
import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CreateEditArticleSchema } from 'Schemas/CreateEditArticleSchema';
import { ArticleService } from 'Services/ArticleService';
import './index.less';

interface CreateEditArticleFormProps {
    articleId?: string;
    articleType: ArticleTypeDto;
    initialValues?: any;
    onCancel?: (success: boolean) => Promise<boolean>;
}

export const CreateEditArticleForm: FunctionComponent<CreateEditArticleFormProps> = observer(
    ({ articleId, articleType, initialValues, onCancel }) => {
        const mainContext = useContext(MainContext);
        const articleService = useService(ArticleService);
        const { toastStore } = useStores();
        const [imageDetails, setImageDetails] = useState<ImageDetails[]>();
        const [isLoading, setIsLoading] = useState(false);
        const [valuesHaveChanged, setValuesHaveChanged] = useState(false);
        const [quilTextFR, setQuilTextFR] = useState<string>();
        const [quilTextEN, setQuilTextEN] = useState<string>();
        const [form] = Form.useForm();
        const [errors, validateForm, resetErrors] = useFormValidation(
            CreateEditArticleSchema,
            form
        );
        const { t } = useTranslation();

        const isEdition = !!initialValues;
        const articleTypeText = articleType === ArticleTypeDto.News ? 'a_news' : 'a_press_release';

        const dismiss = async (success: boolean): Promise<void> => {
            let exit = false;

            if (onCancel) {
                exit = await onCancel(success);
            }

            if (exit) {
                form.resetFields();
                setImageDetails(undefined);
                resetErrors();
            }
        };

        const handleQuillTextFRChange = (value: string): void => {
            form.setFieldsValue({ textFR: value });
            setQuilTextFR(value);
            handleOnChange();
        };

        const handleQuillTextENChange = (value: string): void => {
            form.setFieldsValue({ textEN: value });
            setQuilTextEN(value);
            handleOnChange();
        };

        const handleOnChange = useCallback(() => {
            const formValues = form.getFieldsValue();
            formValues.imageUrl =
                imageDetails === undefined ? initialValues?.imageUrl : imageDetails;

            if (lodash.isEqual(initialValues, formValues)) {
                setValuesHaveChanged(false);
            } else {
                setValuesHaveChanged(true);
            }
        }, [form, imageDetails, initialValues]);

        const submit = async (): Promise<void> => {
            if (isEdition) {
                mainContext?.setIsLoading(true);
            } else {
                setIsLoading(true);
            }

            const formValues = form.getFieldsValue();
            formValues.image = imageDetails
                ? ({
                      delete: imageDetails[0].isDeleted,
                      uploadBase64: imageDetails[0].base64,
                  } as UpdateFileRequestDto)
                : null;
            formValues.type = articleType;
            formValues.textFR = formValues.textFR === '<p><br></p>' ? undefined : formValues.textFR;
            formValues.textEN = formValues.textEN === '<p><br></p>' ? undefined : formValues.textEN;

            try {
                if (!(await validateForm(formValues))) return;

                if (isEdition && articleId) {
                    await articleService.updateArticle(articleId, formValues);

                    mainContext?.articles.fetchArticle &&
                        mainContext.articles.fetchArticle(articleId);

                    initialValues.titleEN = formValues.titleEN;
                    initialValues.titleFR = formValues.titleFR;
                    initialValues.textFR = formValues.textFR;
                    initialValues.textEN = formValues.textEN;
                    initialValues.date = formValues.date;

                    handleOnChange();

                    toastStore.toast({
                        type: 'success',
                        messageKey: t('changes_saved'),
                    });
                } else {
                    const createdId = await articleService.createArticle(formValues);

                    if (createdId) {
                        dismiss(true);
                        toastStore.toast({
                            type: 'success',
                            messageKey: t('added', { param1: t(articleTypeText) }),
                        });
                    }
                }
            } catch (error) {
                if (!(error as any).treated) {
                    toastStore.genericError();
                }
            } finally {
                if (isEdition) {
                    mainContext?.setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            }
        };

        const primaryButton = {
            label: (
                <>
                    <Icon iconName="Add" />
                    {t('add', { param1: t(articleTypeText) })}
                </>
            ),
            onClick: submit,
        };
        const secondaryButton = {
            label: t('cancel'),
            onClick: () => dismiss(false),
        };

        useEffect(() => {
            if (imageDetails !== undefined) handleOnChange();
        }, [handleOnChange, imageDetails]);

        useEffect(() => {
            if (isEdition && initialValues) {
                form.setFieldsValue({
                    titleEN: initialValues.titleEN,
                    titleFR: initialValues.titleFR,
                    textFR: initialValues.textFR,
                    textEN: initialValues.textEN,
                    date: moment.utc(initialValues.date),
                });
            }
        }, [form, isEdition, initialValues]);

        return (
            <>
                <Spin spinning={isLoading} delay={SPIN_DELAY}>
                    <Form
                        className="CreateEditArticleForm"
                        scrollToFirstError
                        layout="vertical"
                        onFinish={submit}
                        form={form}
                    >
                        <Row gutter={formGutter}>
                            <Col span={24}>
                                <ValidatedFormItem errors={errors}>
                                    <ImagePicker
                                        images={
                                            initialValues?.imageUrl
                                                ? [
                                                      {
                                                          url: initialValues.imageUrl,
                                                          uid: 'main',
                                                      } as UploadFile,
                                                  ]
                                                : undefined
                                        }
                                        setImagesDetails={(images: ImageDetails[] | undefined) =>
                                            setImageDetails(images)
                                        }
                                    />
                                </ValidatedFormItem>
                            </Col>

                            <Col span={24}>
                                <ConfigProvider locale={locale}>
                                    <ValidatedFormItem
                                        name="date"
                                        label={t('date')}
                                        errors={errors}
                                    >
                                        <DatePicker
                                            picker="date"
                                            format={DISPLAY_DATE_FORMAT}
                                            allowClear={false}
                                            onChange={handleOnChange}
                                        />
                                    </ValidatedFormItem>
                                </ConfigProvider>
                            </Col>

                            <Col span={12}>
                                <ValidatedFormItem
                                    name="titleFR"
                                    label={t('title', { param1: 'FR' })}
                                    errors={errors}
                                >
                                    <Input onChange={handleOnChange} />
                                </ValidatedFormItem>
                            </Col>

                            <Col span={12}>
                                <ValidatedFormItem
                                    name="titleEN"
                                    label={t('title', { param1: 'EN' })}
                                    errors={errors}
                                >
                                    <Input onChange={handleOnChange} />
                                </ValidatedFormItem>
                            </Col>

                            <Col span={24} className="col-quill">
                                <ReactQuill
                                    theme="snow"
                                    onChange={handleQuillTextFRChange}
                                    value={
                                        quilTextFR
                                            ? quilTextFR || null
                                            : initialValues?.textFR || null
                                    }
                                />

                                <ValidatedFormItem
                                    name="textFR"
                                    label={t('text', { param1: 'FR' })}
                                    errors={errors}
                                >
                                    <Input hidden />
                                </ValidatedFormItem>
                            </Col>

                            <Col span={24} className="col-quill">
                                <ReactQuill
                                    theme="snow"
                                    onChange={handleQuillTextENChange}
                                    value={
                                        quilTextEN
                                            ? quilTextEN || null
                                            : initialValues?.textEN || null
                                    }
                                />

                                <ValidatedFormItem
                                    name="textEN"
                                    label={t('text', { param1: 'EN' })}
                                    errors={errors}
                                >
                                    <Input hidden />
                                </ValidatedFormItem>
                            </Col>

                            {!isEdition && (
                                <>
                                    <Divider />

                                    <Col span={24}>
                                        <BottomButtons
                                            primaryButton={primaryButton}
                                            secondaryButton={secondaryButton}
                                        />
                                    </Col>
                                </>
                            )}
                        </Row>
                    </Form>
                </Spin>

                {isEdition && <ArticleFixedFooter disabled={!valuesHaveChanged} onClick={submit} />}
            </>
        );
    }
);
